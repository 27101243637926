import { Account, AccountType, isAcceptor, isRequestor, User } from '@pocketrn/entities/dist/core';
import {
  ScheduledMeeting,
  ScheduledMeetingJson,
  SnapshotMeetingsFields,
  Meeting,
  QueueStats,
  ClientCallType,
} from '@pocketrn/entities/dist/meeting';
import { meetingActions, ActionKey, REDUCER_KEY } from '../redux/sdk/actions';
import { UserMatchActions } from '../redux/userMatch/actions';
import { Unavailable, NotFound, PermissionDenied, PRNErrorResponse, ManagedProperty } from '@pocketrn/client/dist/entity-sdk';
import { MeetingSDK } from '../../services/firebase/MeetingSDK';
import { MeetingState } from '../redux/sdk/reducer';
import { StateAbbreviation } from '@pocketrn/localizer';
import { ScheduledMeetingsController } from './ScheduledMeetings.controller';
import { Firestore } from 'firebase/firestore';
import { SnapshotHandler } from '../../../../utils/SnapshotHandler';
import { SessionUserController } from '../../../user-state';
import { MatchMakingResponse, UserMatch, buildUserMatch } from '../../../../utils/userMatchHelper';
import { PageController } from '../../../page-state';

const DEFAULT_SEARCH_BUFFER = 1;
const SNAPSHOT_COLLECTION_NAME = 'snapshot_meetings';
const ACCEPTOR_WITH_MORE_THAN_ONE_MEETING = 'expected only one meeting for acceptor';
// @NOTE: Redux does not export its Store type.
export type ReduxStore = any;

export class UserMatchController {
  public sdk: MeetingSDK;
  public store: ReduxStore;
  public firestore: Firestore;
  public scheduledMeetingsController: ScheduledMeetingsController;
  public sessionUserController: SessionUserController;
  public pageController: PageController;
  private snapshotHandler: SnapshotHandler;

  constructor(
    sdk: MeetingSDK,
    store: ReduxStore,
    firestore: Firestore,
    sessionUserController: SessionUserController,
    scheduledMeetingsController: ScheduledMeetingsController,
    pageController: PageController,
  ) {
    this.sdk = sdk;
    this.store = store;
    this.firestore = firestore;
    this.sessionUserController = sessionUserController;
    this.scheduledMeetingsController = scheduledMeetingsController;
    this.pageController = pageController;
    this.snapshotHandler = new SnapshotHandler(
      firestore,
      sessionUserController,
      {
        [SnapshotMeetingsFields.UserMatchLastCheckedAt]: new Date(),
      },
    );
  }

  /**
   * The amount of time to wait before beginning a match search.
   * This prevents sheering from one page state to the next too quickly.
   */
  public searchBufferSeconds: number = DEFAULT_SEARCH_BUFFER;

  /**
   * For checking at the beginning of the app load if the user is currently
   * in the matching queue.  Should be called in the root App.tsx file.
   */
  public async init(
    catchUnregisteredUser?: boolean,
  ): Promise<void> {
    this.setUserMatchLoading(true);
    try {
      await this.getAndSetUserMatch();
    } catch (e) {
      if (catchUnregisteredUser && (e instanceof PermissionDenied)) {
        return;
      } else {
        throw e;
      }
    } finally {
      this.setUserMatchLoading(false);
    }
  }

  // @NOTE: We are doing this in case the user refreshes the page while the
  // scheduled meeting startAt is passed but the meeting is still not created from the scheduler.
  // We want to make sure that we show the scheduled meeting starting modal/screen.
  private async checkIfMeetingWasScheduled(meetingId: string): Promise<boolean> {
    const res = await this.scheduledMeetingsController.getScheduledMeetings();
    const meetingWasScheduled = res.scheduledMeetings.findIndex(
      (sm: ScheduledMeeting | ScheduledMeetingJson) => sm.meetingId === meetingId,
    );
    return meetingWasScheduled !== -1;
  }

  private updateMeetingNotification(meetings: Meeting[]): void {
    this.pageController.setNavbarMeetingNotifications(meetings.length);
  }

  private updateUserMatchState(userMatch: UserMatch): void {
    if (!userMatch.meetings.length) {
      this.clearUserMatchState();
      this.updateMeetingNotification([]);
      return;
    };
    this.updateMeetingNotification(userMatch.meetings.filter(m => m.acceptedAt));
    this.store.dispatch(meetingActions.setListEntities(ActionKey.Meetings, userMatch.meetings));
    this.store.dispatch(meetingActions.setListEntities(ActionKey.Users, userMatch.users));
    this.store.dispatch(meetingActions.setListEntities(ActionKey.Persons, userMatch.persons));
    userMatch.users.map(u => {
      this.store.dispatch(
        meetingActions.setMapEntity(ActionKey.Users, u.uid, u),
      );
    });
    userMatch.persons.map(p => {
      this.store.dispatch(
        meetingActions.setMapEntity(ActionKey.Persons, p.uid, p),
      );
    });
  }

  private clearUserMatchState(): void {
    this.store.dispatch(meetingActions.clearListEntities(ActionKey.Meetings));
    this.store.dispatch(meetingActions.clearListEntities(ActionKey.Users));
    this.store.dispatch(meetingActions.clearListEntities(ActionKey.Persons));
    this.store.dispatch(meetingActions.clearMapEntities(ActionKey.Users));
    this.store.dispatch(meetingActions.clearMapEntities(ActionKey.Persons));
  }

  private setUserMatchLoading(loading: boolean): void {
    this.store.dispatch(meetingActions.setLoading(ActionKey.Meetings, loading));
    this.store.dispatch(meetingActions.setLoading(ActionKey.Users, loading));
    this.store.dispatch(meetingActions.setLoading(ActionKey.Persons, loading));
  }

  public ackMeetingFound(): void {
    this.store.dispatch(UserMatchActions.ackMeetingFound());
  }

  public ackScheduledMeetingStarting(): void {
    this.store.dispatch(UserMatchActions.ackScheduledMeetingStarting());
  }

  public async requestUserMatch(
    providerId: string,
    requestedCallType: ClientCallType,
    region: string,
    requestorNote: string | null,
    managed?: ManagedProperty,
  ): Promise<void> {
    try {
      await this.sdk.requestUserMatch(
        providerId,
        requestedCallType,
        region,
        requestorNote,
        managed,
      );
      this.store.dispatch(UserMatchActions.setMatchmaking(true));
      await this.getAndSetUserMatch();
    } catch (err) {
      this.store.dispatch(UserMatchActions.setMatchmaking(false));
      throw err;
    }
  }

  public async stopUserMatch(): Promise<void> {
    this.store.dispatch(UserMatchActions.setMatchmaking(false));
    this.clearRequestorLeftQueue();
    await this.sdk.stopUserMatch();
    // @NOTE: We call getAndSetUserMatch to rely strictly and only on the backend's
    // response in setting up the userMatch state. And we should avoid updating
    // the meeting snapshots in the backend when calling stopUserMatch, and that's to
    // avoid race conditions between the backend response and the front end state update.
    // And that way only the backend is the source of truth for the userMatch state.
    await this.getAndSetUserMatch();
  }

  public async startMeeting(): Promise<void> {
    try {
      const userMatch = await this.sdk.startMeeting();
      if (!userMatch.meetings[0]) {
        throw new Error('expected userMatch to have a meeting');
      }
      if (userMatch.meetings.length > 1) {
        throw new Error(ACCEPTOR_WITH_MORE_THAN_ONE_MEETING);
      }
      this.updateUserMatchState(userMatch);
    } catch (err) {
      if (err instanceof Unavailable) {
        throw err;
      }
      this.clearUserMatchState();
    }
  }

  public async acceptUserMatch(): Promise<void> {
    this.setUserMatchLoading(true);
    try {
      await this.sdk.acceptUserMatch();
      const userMatch = this.userMatch;
      if (!userMatch?.meetings[0]) {
        throw new Error('expected userMatch to have a meeting');
      }
      if (userMatch.meetings.length > 1) {
        throw new Error(ACCEPTOR_WITH_MORE_THAN_ONE_MEETING);
      }
      userMatch.meetings[0].acceptedAt = new Date();
      this.updateUserMatchState(userMatch);
    } catch (err) {
      if (err instanceof Unavailable) {
        throw err;
      }
      await this.getAndSetUserMatch();
      if (err instanceof NotFound) {
        this.store.dispatch(UserMatchActions.setRequestorLeftQueue(true));
      } else {
        throw err;
      }
    } finally {
      this.setUserMatchLoading(false);
    }
  }

  private get userMatch(): UserMatch | undefined {
    if (!this.meetingState.userMatch.meetings.listEntities.length) {
      return undefined;
    }
    return buildUserMatch({
      meetings: this.meetingState.userMatch.meetings.listEntities,
      users: this.meetingState.userMatch.users.listEntities,
      persons: this.meetingState.userMatch.persons.listEntities,
      providers: this.meetingState.userMatch.providers.mapEntities,
      customCallTypes: this.meetingState.userMatch.customCallTypes.mapEntities,
    });
  }

  private get meetingState(): MeetingState {
    return this.store.getState()[REDUCER_KEY];
  }

  private get sessionUser(): User | undefined {
    return this.sessionUserController.getStoredActiveUser();
  }

  public async declineUserMatch(): Promise<boolean> {
    this.setUserMatchLoading(true);
    try {
      await this.sdk.declineUserMatch();
      await this.getAndSetUserMatch();
      return true;
    } catch (err) {
      if (err instanceof PRNErrorResponse && err.code === 'CANNOT_DECLINE') {
        return false;
      } else {
        throw err;
      }
    } finally {
      this.setUserMatchLoading(false);
    }
  }

  public clearRequestorLeftQueue(): void {
    this.store.dispatch(UserMatchActions.setRequestorLeftQueue(false));
  }

  public async updateActiveRegion(
    region: StateAbbreviation,
    managed?: ManagedProperty,
  ): Promise<void> {
    await this.sdk.setActiveRegion(region, managed);
  }

  public unsubscribeFromSnapshotMeetings(): void {
    this.snapshotHandler.unsubscribe();
  }

  private async setMatchFound(userMatch: UserMatch): Promise<void> {
    this.setUserMatchLoading(true);
    this.clearRequestorLeftQueue();
    const user = this.sessionUserController.getStoredActiveUser();
    if (!user) {
      this.setUserMatchLoading(false);
      throw new Error('cannot start meeting without a valid user');
    }
    const _isRequestor = isRequestor(user.activeAccountType);
    if (!_isRequestor) {
      if (userMatch.meetings.length > 1) {
        this.setUserMatchLoading(false);
        throw new Error(ACCEPTOR_WITH_MORE_THAN_ONE_MEETING);
      } else {
        const meeting = userMatch.meetings[0];
        this.store.dispatch(UserMatchActions.setMatchmaking(false));
        await this.notifyOfScheduledMeetingsStartIfAny(userMatch.meetings);
        if (meeting.endedAt) {
          this.setUserMatchLoading(false);
          return;
        };
      }
    }
    await this.notifyOfScheduledMeetingsStartIfAny(userMatch.meetings);
    this.setUserMatchLoading(false);
  }

  private async notifyOfScheduledMeetingsStartIfAny(meetings: Meeting[]): Promise<void> {
    const meetingsIds = meetings.filter(m => m.acceptedAt && !m.startedAt).map(m => m.id);
    let notifiedOfScheduledMeeting = false;
    let meetingId = '';
    await Promise.all(meetingsIds.map(async m => {
      if (notifiedOfScheduledMeeting) {
        return;
      }
      const meetingWasScheduled = await this.checkIfMeetingWasScheduled(m);
      if (meetingWasScheduled) {
        notifiedOfScheduledMeeting = true;
        meetingId = m;
      }
    }));
    if (notifiedOfScheduledMeeting) {
      this.store.dispatch(UserMatchActions.setScheduledMeetingStarting(meetingId));
    }
  }

  // @NOTE: this could happen when:
  // a. Nurse shift starts but they were using a different account type, so we need to switch to the correct account type.
  // b. on demand or scheduled meeting started for a participant but they were using a different account type.
  private async autoAccountSwitchIfNeeded(res: MatchMakingResponse): Promise<void> {
    const activeAccount = this.sessionUserController.getStoredActiveAccount();
    if (!activeAccount) {
      return;
    }
    const noSwitchNeeded = res.queuedRequestor?.accountType === activeAccount.type ||
    res.queuedAcceptor?.accountType === activeAccount.type ||
      (
        res.userMatch.meetings.length === 1 &&
        (
          res.userMatch.meetings[0].participants.find(
            p => p.uid === activeAccount.uid,
          )?.root.accountType === activeAccount.type
        )
      );
    if (noSwitchNeeded) return;
    const accounts = this.sessionUserController.getStoredAccounts();
    const nurseIsInQueueAndMustSwitch = res.queuedAcceptor && !isAcceptor(activeAccount?.type);
    if (nurseIsInQueueAndMustSwitch) {
      await this.switchToNurseAccount(accounts);
      return;
    }
    if (res.userMatch.meetings.length === 1) {
      const meeting = res.userMatch.meetings[0];
      const meetingProvider = meeting.requestor.providerId;
      const meetingAccountType = meeting.participants.find(
        p => p.uid === activeAccount.uid,
      )?.root.accountType;
      if (!meetingAccountType) {
        return;
      }
      const accountToSwitchTo = accounts.find(a => a.type === meetingAccountType &&
        a.providerId === meetingProvider);
      // @NOTE: this could happen when:
      // a. The user is a nurse in the meeting but was signed in using a different account type.
      // b. The user is a patient in the meeting but was signed in using a different account type.
      if (accountToSwitchTo) {
        await this.sessionUserController.setActiveAccount(accountToSwitchTo);
        return;
      }
      // @NOTE: could happen when the user is a caregiver in the meeting but signed in using a different account type.
      // This may not be caught in accountToSwitchTo as the caregiver
      // could be with a provider that is not the same as the meeting provider.
      const caregiverAccount = accounts.find(a => a.type === AccountType.Caregiver);
      if (caregiverAccount) {
        await this.sessionUserController.setActiveAccount(caregiverAccount);
      }
    }
  }

  private async switchToNurseAccount(accounts: Account[]) {
    const nurseAccount = accounts.find(a => isAcceptor(a.type));
    if (!nurseAccount) {
      throw new Error('cannot find nurse account');
    }
    await this.sessionUserController.setActiveAccount(nurseAccount);
  }

  private updateInQueueState(matchkingResponse: MatchMakingResponse): void {
    const inQueue = !!(
      matchkingResponse.queuedRequestor ||
      matchkingResponse.queuedAcceptor
    );
    this.store.dispatch(UserMatchActions.setMatchmaking(inQueue));
  }

  private updateQueueStatState(queueStats?: QueueStats): void {
    if (queueStats) {
      this.store.dispatch(meetingActions.setActiveEntity(ActionKey.QueueStats, queueStats));
    } else {
      this.store.dispatch(meetingActions.unsetActiveEntity(ActionKey.QueueStats));
    }
  }

  public async checkUserMatch(managed?: ManagedProperty): Promise<MatchMakingResponse> {
    return await this.sdk.checkUserMatch(managed);
  }

  public async getAndSetUserMatch(): Promise<void> {
    const res = await this.checkUserMatch();
    await this.autoAccountSwitchIfNeeded(res);
    this.updateUserMatchState(res.userMatch);
    this.updateInQueueState(res);
    this.updateQueueStatState(res.queueStats);
    if (!res.userMatch?.meetings.length) {
      return;
    }
    await this.setMatchFound(res.userMatch);
  }

  public async subscribeToSnapshotMeetings(): Promise<void> {
    await this.snapshotHandler.subscribe(
      SNAPSHOT_COLLECTION_NAME,
      () => this.unsubscribeFromSnapshotMeetings(),
      [
        {
          dateKey: SnapshotMeetingsFields.UserMatchLastCheckedAt,
          callback: async () => this.getAndSetUserMatch(),
          pingFallbackSeconds: 5,
        },
      ],
    );
  }
}
