import {
  NavbarActionId,
  NavbarActiveNavAction,
  NavbarIsAccountSwitcherAction,
  NavbarMeetingNotificationsAction,
  NavbarOnlyLogoAction,
  NavbarProfileImageAction,
  NavbarShowLogoutAction,
} from './navbar/actions';
import { Action } from '@pocketrn/client/dist/entity-sdk';
import { Navigation, PageLoadingDetails } from '@pocketrn/rn-designsystem';
import {
  PageActionId,
  PageTitleAction,
  PageHeaderActionTextAction,
  PageShowOnHeaderAction,
  PageTriggerOnHeaderAction,
  PageDescriptionAction,
  PageInnerPageAction,
  PageSideStripeAction,
  PageOverlineAction,
  PageLoadingAction,
  PageTriggerOnBackAction,
  PageShowOnBackAction,
  PagePropsAction,
  PageVectorImageAction,
} from './actions';

export interface NavbarState {
  activeNavigation: Navigation;
  meetingNotifications: number;
  profileImage: string;
  onlyLogo: boolean;
  isAccountSwitcher: boolean;
  showLogout: boolean;
}

export type PageSideStrip = 'hide' | 'min' | 'max';

export interface PageState {
  initialLoading: boolean;
  loading: boolean | PageLoadingDetails;
  title: string;
  vectorImage: string;
  headerActionText: string;
  showOnHeaderAction: boolean;
  triggerHeaderAction: boolean;
  description: string;
  innerPage: boolean;
  innerPageTitle: string;
  sideStripe: PageSideStrip;
  overline: string;
  triggerOnBack: boolean;
  showOnBack: boolean;
  navBar: NavbarState;
  fullWidth: boolean;
}

const initialState: PageState = {
  initialLoading: true,
  loading: true,
  title: '',
  vectorImage: '',
  headerActionText: '',
  showOnHeaderAction: false,
  triggerHeaderAction: false,
  description: '',
  innerPage: false,
  innerPageTitle: '',
  sideStripe: 'hide',
  overline: '',
  triggerOnBack: false,
  showOnBack: false,
  navBar: {
    activeNavigation: Navigation.Home,
    meetingNotifications: 0,
    profileImage: '',
    onlyLogo: false,
    isAccountSwitcher: false,
    showLogout: false,
  },
  fullWidth: false,
};

const pageReducer = (state = initialState, action: Action): PageState => {
  const newState = state;
  switch (action.type) {
    case PageActionId.FINISH_INITIAL_LOADING:
      return State.finishInitialLoading(newState);
    case PageActionId.SET_LOADING:
      return State.setPageLoading(newState, action as PageLoadingAction);
    case PageActionId.SET_TITLE:
      return State.setPageTitle(newState, action as PageTitleAction);
    case PageActionId.SET_VECTOR_IMAGE:
      return State.setPageVectorImage(newState, action as PageVectorImageAction);
    case PageActionId.SET_HEADER_ACTION_TEXT:
      return State.setPageHeaderActionText(newState, action as PageHeaderActionTextAction);
    case PageActionId.SET_SHOW_ON_HEADER_ACTION:
      return State.setPageShowOnHeaderAction(newState, action as PageShowOnHeaderAction);
    case PageActionId.SET_HEADER_ACTION:
      return State.setPageTriggerHeaderAction(newState, action as PageTriggerOnHeaderAction);
    case PageActionId.SET_DESCRIPTION:
      return State.setPageDescription(newState, action as PageDescriptionAction);
    case PageActionId.SET_INNER_PAGE:
      return State.setPageInnerPage(newState, action as PageInnerPageAction);
    case PageActionId.SET_SIDE_STRIPE:
      return State.setPageSideStripe(newState, action as PageSideStripeAction);
    case PageActionId.SET_OVERLINE:
      return State.setPageOverline(newState, action as PageOverlineAction);
    case PageActionId.SET_IS_ON_BACK:
      return State.setPageTriggerOnBack(newState, action as PageTriggerOnBackAction);
    case PageActionId.SET_SHOW_ON_BACK:
      return State.setPageShowOnBack(newState, action as PageShowOnBackAction);
    case PageActionId.SET_PAGE_PROPS:
      return State.setPageProps(newState, action as PagePropsAction);
    case NavbarActionId.SET_ACTIVE_NAVIGATION:
      return State.setNavbarActiveNavigation(newState, action as NavbarActiveNavAction);
    case NavbarActionId.SET_IS_ACCOUNT_SWITCHER:
      return State.setNavbarIsAccountSwitcher(newState, action as NavbarIsAccountSwitcherAction);
    case NavbarActionId.SET_MEETING_NOTIFICATIONS:
      return State.setNavbarMeetingNotifications(
        newState,
        action as NavbarMeetingNotificationsAction,
      );
    case NavbarActionId.SET_PROFILE_IMAGE:
      return State.setNavbarProfileImage(newState, action as NavbarProfileImageAction);
    case NavbarActionId.SET_ONLY_LOGO:
      return State.setNavbarOnlyLogo(newState, action as NavbarOnlyLogoAction);
    case NavbarActionId.SET_SHOW_LOGOUT:
      return State.setNavbarShowLogout(newState, action as NavbarShowLogoutAction);
    default:
      return state;
  }
};

class State {
  public static finishInitialLoading = (
    state: PageState,
  ): PageState => {
    return { ...state, initialLoading: false };
  };

  public static setPageLoading = (
    state: PageState,
    action: PageLoadingAction,
  ): PageState => {
    return { ...state, loading: action.loading };
  };

  public static setPageTitle = (
    state: PageState,
    action: PageTitleAction,
  ): PageState => {
    return { ...state, title: action.title };
  };

  public static setPageVectorImage = (
    state: PageState,
    action: PageVectorImageAction,
  ): PageState => {
    return { ...state, vectorImage: action.vectorImage };
  };

  public static setPageHeaderActionText = (
    state: PageState,
    action: PageHeaderActionTextAction,
  ): PageState => {
    return { ...state, headerActionText: action.headerActionText };
  };

  public static setPageShowOnHeaderAction = (
    state: PageState,
    action: PageShowOnHeaderAction,
  ): PageState => {
    return { ...state, showOnHeaderAction: action.showOnHeaderAction };
  };

  public static setPageTriggerHeaderAction = (
    state: PageState,
    action: PageTriggerOnHeaderAction,
  ): PageState => {
    return { ...state, triggerHeaderAction: action.triggerHeaderAction };
  };

  public static setPageDescription = (
    state: PageState,
    action: PageDescriptionAction,
  ): PageState => {
    return { ...state, description: action.description };
  };

  public static setPageInnerPage = (
    state: PageState,
    action: PageInnerPageAction,
  ): PageState => {
    return { ...state, innerPage: action.innerPage };
  };

  public static setPageSideStripe = (
    state: PageState,
    action: PageSideStripeAction,
  ): PageState => {
    return { ...state, sideStripe: action.sideStripe };
  };

  public static setPageOverline = (
    state: PageState,
    action: PageOverlineAction,
  ): PageState => {
    return { ...state, overline: action.overline };
  };

  public static setPageTriggerOnBack = (
    state: PageState,
    action: PageTriggerOnBackAction,
  ): PageState => {
    return { ...state, triggerOnBack: action.triggerOnBack };
  };

  public static setPageShowOnBack = (
    state: PageState,
    action: PageShowOnBackAction,
  ): PageState => {
    return { ...state, showOnBack: action.showOnBack };
  };

  public static setPageProps = (
    state: PageState,
    action: PagePropsAction,
  ): PageState => {
    return {
      ...state,
      title: action.title,
      vectorImage: action.vectorImage,
      headerActionText: action.headerActionText,
      description: action.description,
      innerPage: action.innerPage,
      innerPageTitle: action.innerPageTitle,
      sideStripe: action.sideStripe,
      overline: action.overline,
      navBar: {
        ...state.navBar,
        activeNavigation: action.activeNavItem,
        isAccountSwitcher: action.isAccountSwitcher,
      },
      fullWidth: action.fullWidth,
    };
  };

  public static setNavbarActiveNavigation = (
    state: PageState,
    action: NavbarActiveNavAction,
  ): PageState => {
    return {
      ...state, navBar: {
        ...state.navBar,
        activeNavigation: action.activeNavigation,
      },
    };
  };

  public static setNavbarMeetingNotifications = (
    state: PageState,
    action: NavbarMeetingNotificationsAction,
  ): PageState => {
    return {
      ...state, navBar: {
        ...state.navBar,
        meetingNotifications: action.meetingNotifications,
      },
    };
  };

  public static setNavbarProfileImage = (
    state: PageState,
    action: NavbarProfileImageAction,
  ): PageState => {
    return {
      ...state, navBar: {
        ...state.navBar,
        profileImage: action.profileImage,
      },
    };
  };

  public static setNavbarOnlyLogo = (
    state: PageState,
    action: NavbarOnlyLogoAction,
  ): PageState => {
    return {
      ...state, navBar: {
        ...state.navBar,
        onlyLogo: action.onlyLogo,
      },
    };
  };

  public static setNavbarIsAccountSwitcher = (
    state: PageState,
    action: NavbarIsAccountSwitcherAction,
  ): PageState => {
    return {
      ...state, navBar: {
        ...state.navBar,
        isAccountSwitcher: action.isAccountSwitcher,
      },
    };
  };

  public static setNavbarShowLogout = (
    state: PageState,
    action: NavbarShowLogoutAction,
  ): PageState => {
    return {
      ...state, navBar: {
        ...state.navBar,
        showLogout: action.showLogout,
      },
    };
  };
}

export default pageReducer;
