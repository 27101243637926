import { User } from '@pocketrn/entities/dist/core';
import { Meeting } from '@pocketrn/entities/dist/meeting';

export function myInProgressMeetings(
  inProgressMeetings: Meeting[],
  user: User | undefined,
): Meeting[] {
  if (!user) return [];
  return inProgressMeetings.filter(m => {
    return m.participants.find(p => p.uid === user?.uid) !== undefined;
  });
}
