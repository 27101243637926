import { OptOutPhone, ProfileSettings, User, UserPhoneJson } from '@pocketrn/entities/dist/core';
import { CoreSDK } from '../../services/firebase/CoreSDK';
import { SessionUserController } from '../../apps/user-state/index';
import { CommunitySDK } from '../../services/firebase/CommunitySDK';
import { Person } from '@pocketrn/entities/dist/community';
import { ManagedProperty } from '@pocketrn/client/dist/entity-sdk';

// @NOTE: Redux does not export its Store type.
export type ReduxStore = any;

export class ProfileController {
  public coreSdk: CoreSDK;
  public communitySdk: CommunitySDK;
  public store: ReduxStore;
  public sessionUserController: SessionUserController;

  constructor(
    coreSdk: CoreSDK,
    communitySdk: CommunitySDK,
    store: ReduxStore,
    sessionUserController: SessionUserController,
  ) {
    this.coreSdk = coreSdk;
    this.communitySdk = communitySdk;
    this.store = store;
    this.sessionUserController = sessionUserController;
  }

  public async declareEmergency(meetingId: string, managed: ManagedProperty): Promise<{
    person: Person;
    user: User;
  }> {
    return await this.communitySdk.declareEmergency(meetingId, managed);
  }

  public async setEnableSmsNotifications(enabled: boolean, managedUser?: User): Promise<User> {
    let user = managedUser;
    if (!managedUser) {
      this.sessionUserController.setStoredUserLoading(true);
      user = this.sessionUserController.getStoredActiveUser();
    }
    if (!user) {
      throw new Error('No session user');
    }
    if (!user.profileSettings) {
      user.profileSettings = new ProfileSettings();
    }
    user.profileSettings.enableSmsNotifications = enabled;
    await this.coreSdk.setProfileSettings(user.profileSettings);
    if (!managedUser) {
      this.sessionUserController.setStoredActiveUser(user);
      this.sessionUserController.setStoredUserLoading(false);
    }
    return user;
  }

  public async setNotifyWhileUsingApp(enabled: boolean, managedUser?: User): Promise<User> {
    let user = managedUser;
    if (!managedUser) {
      this.sessionUserController.setStoredUserLoading(true);
      user = this.sessionUserController.getStoredActiveUser();
    }
    if (!user) {
      throw new Error('No session user');
    }
    if (!user.profileSettings) {
      user.profileSettings = new ProfileSettings();
    }
    user.profileSettings.notifyWhileUsingApp = enabled;
    await this.coreSdk.setProfileSettings(user.profileSettings);
    if (!managedUser) {
      this.sessionUserController.setStoredActiveUser(user);
      this.sessionUserController.setStoredUserLoading(false);
    }
    return user;
  }

  public async retrieveOptOutPhoneStatus(managed?: ManagedProperty): Promise<OptOutPhone[]> {
    return await this.coreSdk.getOptOutPhones(managed);
  }

  public async setPhones(phones: UserPhoneJson[], managed?: ManagedProperty): Promise<void> {
    await this.coreSdk.setPhones(phones, { managed });
    if (!managed) {
      await this.sessionUserController.retrieveUser();
    }
  }

  public async updateProfile(person: Person, managed?: ManagedProperty): Promise<void> {
    if (!managed) {
      this.sessionUserController.setStoredPersonLoading(true);
    }
    await this.communitySdk.updatePerson(person, managed);
    if (!managed) {
      this.sessionUserController.setStoredActivePerson(person);
      this.sessionUserController.setStoredPersonLoading(false);
    }
  }

  public async updateProfileSettingSession(
    person: Person,
    managed?: ManagedProperty,
  ): Promise<void> {
    await this.communitySdk.updatePerson(person, managed);
  }
}
