import React, { FunctionComponent, ReactElement } from 'react';
import { TextType } from '../../../entities/enums/TextType';
import { Text } from '../../../entities/Text';
import styles from './ChatText.module.css';
import { LocalePrimer } from '@pocketrn/localizer';
import { Person } from '@pocketrn/entities/dist/community';

interface ChatTextProps {
  text: Text;
  sender?: Person;
  localePrimer: LocalePrimer;
}

export const ChatText: FunctionComponent<ChatTextProps> = (props): ReactElement => {
  const getWrapperStyles = (): string => {
    const s = [styles.wrapper];
    if (props.text.type === TextType.Bot) {
      s.push(styles.bot);
    } else if (props.text.type === TextType.Event) {
      s.push(styles.event);
    } else if (props.text.type === TextType.Other) {
      s.push(styles.other);
    } else if (props.text.type === TextType.Self) {
      s.push(styles.self);
    }
    return s.join(' ');
  };

  const getTime = (): string => {
    if (!props.text.sentAt) {
      return '';
    }
    return props.localePrimer.translate('format.date.onlyTime')(props.text.sentAt);
  };

  const getText = (): string => {
    return props.text.value;
  };

  return (
    <div className={styles.outerWrapper}>
      <div className={getWrapperStyles()}>
        <div className={styles.info}>
          <div className={styles.sender}>{props.sender?.shortDisplayName(props.localePrimer) ?? ''}</div>
          <div className={styles.date}>{getTime()}</div>
        </div>
        <div className={styles.text}>{getText()}</div>
      </div>
    </div>
  );
};
