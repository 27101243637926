import { Account, AccountStatus, AccountType, isAcceptor, Provider } from '@pocketrn/entities/dist/core';
import React, { FunctionComponent, ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import localePrimer from '../../../utils/localePrimer';
import { sessionUserController } from '../../../utils/userStateInit';
import { selectInQueue } from '../../../apps/meeting-app';
import {
  DefaultProps,
  MenuButton,
  UserIcon,
  RNIcon,
  TechnicianIcon,
  MenuItem,
  MenuItemGroup,
  Menu,
  PlusIcon,
} from '@pocketrn/rn-designsystem';
import {
  selectSessionAccount,
  selectSessionAccountLoading,
  selectSessionUserAccounts,
  selectSessionUserProviderMap,
} from '../../../apps/user-state/index';
import { usePageLoading } from '../../../utils/usePageLoading';
import { useNavigate } from 'react-router-dom';
import { CoreRoute } from '../../../entities/Router';

export interface AccountSwitcherProps {
  inline?: boolean;
  loading?: boolean;
  disabled?: boolean;
  background?: 'gray' | 'white';
  testIdRoot?: string;
}

const locale = 'core.accountSwitcher';

export const getAccountTypeIcon = (type: AccountType): ReactElement => {
  switch (type) {
    case AccountType.Patient:
      return <UserIcon color="prnBlue" />;
    case AccountType.Nurse:
      return <RNIcon color="prnBlue" />;
    case AccountType.Technician:
      return <TechnicianIcon color="prnBlue" />;
    case AccountType.Caregiver:
      return <UserIcon count={2} color="prnBlue" />;
    case AccountType.UserAdmin:
      return <TechnicianIcon color="error" />;
    case AccountType.PermissionsAdmin:
      return <TechnicianIcon color="error" />;
    default:
      if (isAcceptor(type)) {
        return <RNIcon color="error" />;
      }
      throw new Error(`unexpected account type: ${type}`);
  }
};

export const getAccountTypeTranslation = (type: AccountType): string => {
  switch (type) {
    case AccountType.Patient:
      return localePrimer.translate(locale, 'accountType.patient');
    case AccountType.Nurse:
      return localePrimer.translate(locale, 'accountType.nurse');
    case AccountType.Technician:
      return localePrimer.translate(locale, 'accountType.technician');
    case AccountType.Caregiver:
      return localePrimer.translate(locale, 'accountType.caregiver');
    case AccountType.UserAdmin:
      return localePrimer.translate(locale, 'accountType.userAdmin');
    case AccountType.PermissionsAdmin:
      return localePrimer.translate(locale, 'accountType.permissionsAdmin');
    default:
      throw new Error(`unexpected account type: ${type}`);
  }
};

interface AccountItem extends DefaultProps {
  provider?: Provider
  account: Account;
  definedProvider: boolean;
  availableAccountIndex?: number;
}

export const AccountSwitcher: FunctionComponent<AccountSwitcherProps> = (props): ReactElement => {
  const [ isOpened, setIsOpened ] = useState(false);
  const accounts = useSelector(selectSessionUserAccounts);
  const activeAccount = useSelector(selectSessionAccount);
  const accountLoading = useSelector(selectSessionAccountLoading);
  const providerMap = useSelector(selectSessionUserProviderMap);
  const inQueue = useSelector(selectInQueue);
  const navigate = useNavigate();
  const [ loading, setLoading ] = useState(null as null | boolean);
  usePageLoading(loading);

  const testIdRoot = props.testIdRoot ?? 'accountSwitcher-menu';

  let availableAccountItems: AccountItem[] = accounts.map(account => {
    let definedProvider = false;
    let provider;
    if (account.providerId && account.providerId in providerMap) {
      provider = providerMap[account.providerId];
      definedProvider = true;
    }
    return {
      provider,
      account,
      definedProvider,
    };
  });

  availableAccountItems = availableAccountItems.filter(item => {
    return item.account.status === AccountStatus.Approved &&
      (item.definedProvider || item.account.type === AccountType.Technician);
  });

  availableAccountItems.forEach((item, index) => {
    item.availableAccountIndex = index;
    availableAccountItems[index] = item;
  });

  const accountTypes = new Set<AccountType>(availableAccountItems.map(item => item.account.type));

  const handleNavigate = (route: CoreRoute) => {
    setIsOpened(false);
    navigate(route);
  };

  const activeAccountItemIndex = availableAccountItems.findIndex((item, index) => {
    if (!activeAccount) {
      return false;
    }
    if (activeAccount.status !== AccountStatus.Suspended) {
      return (
        activeAccount.providerId === item.account.providerId &&
        activeAccount.type === item.account.type &&
        activeAccount.uid === item.account.uid
      );
    }
  });

  const getActiveAccountItemValuePath = (): string[] => {
    if (accountLoading || activeAccountItemIndex === -1) {
      return [];
    }
    const item = availableAccountItems[activeAccountItemIndex];
    if (isAcceptor(item.account.type)) {
      return [AccountType.Nurse];
    } else if (item.account.type === AccountType.Technician) {
      return [AccountType.Technician];
    } else if (accountTypes.size === 1) {
      return [item.account.providerId];
    }
    return [ item.account.type, item.account.providerId ];
  };

  const selectAccount = async (valuePath: string[]) => {
    const selectedAccountValue = valuePath[valuePath.length - 1];
    let selectedAccount;
    if (selectedAccountValue === AccountType.Nurse) {
      selectedAccount = availableAccountItems.find(
        accountItem => isAcceptor(accountItem.account.type),
      )?.account;
    } else if (selectedAccountValue === AccountType.Technician) {
      selectedAccount = availableAccountItems.find(
        accountItem => accountItem.account.type === AccountType.Technician,
      )?.account;
    } else if (accountTypes.size === 1) {
      const selectedAccountType = availableAccountItems[0].account.type;
      selectedAccount = availableAccountItems.find(
        accountItem => {
          return accountItem.account.providerId === selectedAccountValue &&
          accountItem.account.type === selectedAccountType;
        },
      )?.account;
    } else {
      const selectedAccountType = valuePath[0];
      selectedAccount = availableAccountItems.find(
        accountItem => {
          return accountItem.account.providerId === selectedAccountValue &&
          accountItem.account.type === selectedAccountType;
        },
      )?.account;
    }

    if (!selectedAccount) {
      return;
    }
    setLoading(true);
    await sessionUserController.setActiveAccount(selectedAccount);
    setLoading(false);
  };

  const getProviderMenuItems = (accountsItems: AccountItem[]): ReactElement[] => {
    return accountsItems.map((item, index) => {
      return (
        <MenuItem
          key={index}
          value={item.account.providerId}
          text={item.provider?.name || '???'}
        />
      );
    });
  };

  const getMenuItems = (): ReactElement[] => {
    if (accountTypes.size === 1) {
      if (accountTypes.has(AccountType.Patient) || accountTypes.has(AccountType.Caregiver)) {
        const menuItems = getProviderMenuItems(availableAccountItems);
        menuItems.push(
          <MenuItem
            value="newClinic"
            text={localePrimer.translate(locale, 'addAnotherAccount')}
            style="link"
            onSelectItem={() => handleNavigate(CoreRoute.ManageClinics)}
            icon={<PlusIcon color="prnBlue" />}
          />,
        );
        return menuItems;
      }
    }

    const patientAccts: AccountItem[] = [];
    const caregiverAccts: AccountItem[] = [];
    const acceptorAccts: AccountItem[] = [];
    const technicianAccts: AccountItem[] = [];
    const otherAccts: AccountItem[] = [];

    availableAccountItems.forEach(value => {
      const accountType = value.account.type;
      if (isAcceptor(accountType)) {
        acceptorAccts.push(value);
      } else if (accountType === AccountType.Caregiver) {
        caregiverAccts.push(value);
      } else if (accountType === AccountType.Patient) {
        patientAccts.push(value);
      } else if (accountType === AccountType.Technician) {
        technicianAccts.push(value);
      } else {
        otherAccts.push(value);
      }
    });

    const menuItems: ReactElement[] = [];
    if (patientAccts.length > 0) {
      menuItems.push(
        <MenuItemGroup
          text={getAccountTypeTranslation(AccountType.Patient)}
          icon={getAccountTypeIcon(AccountType.Patient)}
          value="patient"
          items={getProviderMenuItems(patientAccts)}
        />,
      );
    }
    if (caregiverAccts.length > 0) {
      menuItems.push(
        <MenuItemGroup
          text={getAccountTypeTranslation(AccountType.Caregiver)}
          icon={getAccountTypeIcon(AccountType.Caregiver)}
          value="caregiver"
          items={getProviderMenuItems(caregiverAccts)}
        />,
      );
    }
    if (acceptorAccts.length > 0) {
      menuItems.push(
        <MenuItem
          text={getAccountTypeTranslation(AccountType.Nurse)}
          icon={getAccountTypeIcon(AccountType.Nurse)}
          value={AccountType.Nurse}
        />,
      );
    }
    if (technicianAccts.length > 0) {
      menuItems.push(
        <MenuItem
          text={getAccountTypeTranslation(AccountType.Technician)}
          icon={getAccountTypeIcon(AccountType.Technician)}
          value={AccountType.Technician}
        />,
      );
    }
    if (otherAccts.length > 0) {
      const otherAccountTypes = new Set<AccountType>(otherAccts.map(item => item.account.type));
      otherAccountTypes.forEach(accountType => {
        menuItems.push(
          <MenuItemGroup
            text={getAccountTypeTranslation(accountType)}
            icon={getAccountTypeIcon(accountType)}
            value={accountType}
            items={
              getProviderMenuItems(otherAccts.filter(acct => acct.account.type === accountType))
            }
          />,
        );
      });
    }

    menuItems.push(
      <MenuItem
        value="newClinic"
        text={localePrimer.translate(locale, 'addAnotherAccount')}
        style="link"
        onSelectItem={() => handleNavigate(CoreRoute.ManageClinics)}
        icon={<PlusIcon color="prnBlue" />}
      />,
    );

    return menuItems;
  };

  const menuBtn = activeAccountItemIndex !== -1 ? (
    isAcceptor(availableAccountItems[activeAccountItemIndex].account.type) ?
      (
        <MenuButton
          text={getAccountTypeTranslation(AccountType.Nurse)}
          nonPlaceholderItemSelected
          opened={isOpened}
        />
      ) : availableAccountItems[activeAccountItemIndex].account.type === AccountType.Technician ? (
        <MenuButton
          text={getAccountTypeTranslation(AccountType.Technician)}
          nonPlaceholderItemSelected
          opened={isOpened}
        />
      ) : (
        <MenuButton
          text={availableAccountItems[activeAccountItemIndex].provider?.name || '???'}
          nonPlaceholderItemSelected
          opened={isOpened}
        />
      )

  ) : accountLoading ? (
    <MenuButton
      text={localePrimer.translate(locale, 'loading')}
      nonPlaceholderItemSelected={false}
      opened={isOpened}
    />
  ) : (
    <MenuButton
      text={localePrimer.translate(locale, 'selectAccount')}
      nonPlaceholderItemSelected={false}
      opened={isOpened}
    />
  );

  return (
    <Menu
      size="chip"
      {...props}
      loading={accountLoading || !!loading}
      button={menuBtn}
      isOpen={isOpened}
      onOpen={setIsOpened}
      onSelectItem={selectAccount}
      selectedValue={getActiveAccountItemValuePath()}
      disabled={inQueue}
      inline={props.inline}
      lockSelected={availableAccountItems.length < 1}
      testIdRoot={testIdRoot}
      items={getMenuItems()}
    />
  );
};
