import { NavbarActions } from './../redux/navbar/actions';
import { PageActions } from '../redux/actions';
import { PageSideStrip } from '../redux/reducer';
import { Navigation, PageLoadingDetails } from '@pocketrn/rn-designsystem';

// @NOTE: Redux does not export its Store type.
export type ReduxStore = any;

export class PageController {
  public store: ReduxStore;

  constructor(
    store: ReduxStore,
  ) {
    this.store = store;
  }

  public setLoading(loading: boolean | PageLoadingDetails): void {
    this.store.dispatch(PageActions.setPageLoading(loading));
  };

  public setTitle(title: string): void {
    this.store.dispatch(PageActions.setPageTitle(title));
  };

  public setVectorImage(vectorImage: string): void {
    this.store.dispatch(PageActions.setPageVectorImage(vectorImage));
  };

  public setHeaderActionText(headerActionText: string): void {
    this.store.dispatch(PageActions.setPageHeaderActionText(headerActionText));
  };

  public setShowOnHeaderAction(showOnHeaderAction: boolean): void {
    this.store.dispatch(PageActions.setPageShowOnHeaderAction(showOnHeaderAction));
  };

  public setTriggerHeaderAction(triggerHeaderAction: boolean): void {
    this.store.dispatch(PageActions.setPageTriggerHeaderAction(triggerHeaderAction));
  };

  public setDescription(description: string): void {
    this.store.dispatch(PageActions.setPageDescription(description));
  };

  public setInnerPage(innerPage: boolean): void {
    this.store.dispatch(PageActions.setPageInnerPage(innerPage));
  };

  public setSideStripe(sideStripe: PageSideStrip): void {
    this.store.dispatch(PageActions.setPageSideStripe(sideStripe));
  };

  public setOverline(overline: string): void {
    this.store.dispatch(PageActions.setPageOverline(overline));
  };

  public setTriggerOnBack(triggerOnBack: boolean): void {
    this.store.dispatch(PageActions.setPageTriggerOnBack(triggerOnBack));
  };

  public setShowOnBack(showOnBack: boolean): void {
    this.store.dispatch(PageActions.setPageShowOnBack(showOnBack));
  };

  public setPageProps(
    props: {
      title: string,
      vectorImage?: string,
      headerActionText?: string,
      description?: string,
      innerPage?: boolean,
      innerPageTitle?: string,
      sideStripe?: PageSideStrip,
      overline?: string,
      activeNavItem?: Navigation,
      isAccountSwitcher?: boolean,
      fullWidth?: boolean,
    },
  ): void {
    this.store.dispatch(PageActions.setPageProps(props));
    this.store.dispatch(PageActions.finishInitialLoading());
  };

  public setNavbarActiveNav(navigation: Navigation): void {
    this.store.dispatch(NavbarActions.setNavbarActiveNavAction(navigation));
  };

  public setNavbarMeetingNotifications(notifications: number): void {
    this.store.dispatch(NavbarActions.setNavbarMeetingNotificationsAction(notifications));
  };

  public setNavbarOnlyLogo(onlyLogo: boolean): void {
    this.store.dispatch(NavbarActions.setNavbarOnlyLogoAction(onlyLogo));
  };

  public setNavbarProfileImage(profileImage: string): void {
    this.store.dispatch(NavbarActions.setNavbarProfileImageAction(profileImage));
  };

  public setNavbarIsAccountSwitcher(isAccountSwitcher: boolean): void {
    this.store.dispatch(NavbarActions.setNavbarIsAccountSwitcherAction(isAccountSwitcher));
  };

  public setNavbarShowLogout(showLogout: boolean): void {
    this.store.dispatch(NavbarActions.setNavbarShowLogoutAction(showLogout));
  };
}
