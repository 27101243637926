import { Compact, LicenseType } from '@pocketrn/entities/dist/core';

export const technician = {
  createProvider: {
    overviewTitle: 'Create Provider',
    overviewDescription: 'Create a new provider entity in PocketRN.',
    creationSuccess: 'Provider created!',
    providerId: 'Provider Id',
    providerIdHelper: 'Must be unique. Cannot change after creation.',
    providerIdAlreadyExists: 'A provider already exists with this id.',
    providerName: 'Provider Name',
    uniqueNote: 'Must be unique',
    providerNameAlreadyExists: 'A provider already exists with this name.',
    create: 'Create',
  },
  setFeatureFlag: {
    overviewTitle: 'Set Feature Flag',
    overviewDescription: 'Create a new feature flag in PocketRN or override an existing feature with the same Groupd ID + ID',
    updateSuccess: 'Feature Flag set!',
    id: 'ID',
    groupId: 'Group ID',
    groupIdHelperText: 'Recommended to be the namespace',
    update: 'Set/Create',
    error: {
      notLongEnough: 'Not long enough',
      invalidPriority: 'Priority must be either empty or an integer',
    },
    find: 'Find',
    value: 'Value',
    valueHelperText: 'Can be a number, boolean, or string',
    priority: 'Priority',
    priorityHelperText: 'Can be empty or an integer',
    featureProvider: {
      noProvider: 'No Provider',
      label: 'Provider ID',
    },
  },
  escalate: {
    overviewTitle: 'Escalate',
    overviewDescription: 'Escalate a meeting after an experienced nurse joins the session with the patient',
    form: {
      placeholder: 'Select Meeting',
      meetingId: 'Meeting ID',
      firstName: 'First Name',
      lastName: 'Last Name',
      submit: 'Submit',
    },
    escalateSuccess: 'Meeting escalated successfuly',
    escalateError: 'Unable to escalate meeting.',
  },
  inviteUser: {
    overviewTitle: 'Invite User',
    overviewDescription: 'Invite a user to join a PocketRN provider.',
    form: {
      email: {
        label: 'Email',
        placeholder: 'Email of existing or new user',
        emailCannotHavePlus: 'Email cannot contain a +. Remove the + and all characters between the + and @ symbols.',
        emailAlreadyExists: 'A user with this email already exists.',
      },
      provider: { label: 'Provider' },
      accountType: {
        label: 'Account Type',
        errorTechnicianOnProvider: 'A Technician cannot be assigned to a Provider',
        errorOnProvider: 'Only a Technician can be invited to the PocketRN Provider',
        errorOnDefaultProvider: 'Only a nurse can be invited to the PocketRN Default Provider',
      },
      creditDays: 'Trial Days',
      creditProductKey: 'Trial Plan',
      invalidCreditDaysValue: 'Trial days must be a nonnegative number',
      d2c: 'Skip sending the invite email',
    },
    invite: 'Invite User',
    inviteSuccess: 'Invite sent!',
    manage: 'Manage',
    inviteAnother: 'Invite Another',
    accountAlreadyExists: 'An account with these exact parameters already exists.',
  },
  manageUsers: {
    overviewTitle: 'Manage Users',
    overviewDescription: 'Lookup users information from stat data tokens or from their email. You can also impersonate a user.',
    form: {
      emailOrToken: {
        label: 'Email, Phone #, UID, or Token',
        emailCannotHavePlus: 'Email cannot contain a +. Remove the + and all characters between the + and @ symbols.',
      },
      error: {
        invalidEmailOrToken: (tokenLength: number): string => `Input invalid. If token, it must be ${tokenLength} characters.`,
        notFound: 'Could not find user\'s token or email',
        invalidCreditEndsAt: 'Invalid Trial Date',
        insufficientCreditEndsAt: (minimumCreditDays: number): string => `Credit days must be at least ${minimumCreditDays} days`,
        subscriptionRequired: 'Discounts are only applicable to users with active subscriptions.',
        guestPatientImpersonate: 'Cannot impersonate a guest patient.',
      },
    },
    phones: { title: 'Phones' },
    find: 'Find',
    licenses: {
      title: 'Licenses',
      add: 'Add License',
      edit: 'Edit License',
      form: {
        jurisdiction: 'Jurisdiction',
        type: 'Type',
        expirationDate: 'Expiration Date',
      },
      jurisdiction: {
        [Compact.NLC]: 'Nurse Licensure Compact',
        [Compact.APRN]: 'Advanced Practice Registered Nurse Compact',
      },
      type: {
        [LicenseType.CertifiedNursingAssistant]: 'Certified Nursing Assistant',
        [LicenseType.MedicalAssistant]: 'Medical Assistant',
        [LicenseType.LicensedVocationalNurse]: 'Licensed Vocational Nurse',
        [LicenseType.LicensedPracticalNurse]: 'Licensed Practical Nurse',
        [LicenseType.RegisteredNurse]: 'Registered Nurse',
        [LicenseType.NursePractitioner]: 'Nurse Practitioner',
        [LicenseType.DoctorOfMedicine]: 'Doctor of Medicine',
      },
      details: {
        expirationDate: 'Expiration Date',
        link: 'Link',
      },
    },
    manage: 'Impersonate User',
    creditEnds: 'Trial Expiration',
    nextBillingDate: 'Next Billing Date',
    warning: { moreThanOneUser: (count: number): string => `Found ${count} users matching this search.` },
    infiniteCredit: (maxCreditDays: number): string => `Trial days of more than ${maxCreditDays} will grant users an infinite amount of credit.`,
    discount: 'Discount',
    currentDiscountAmount: (amount: number): string => `$${amount}`,
    currentDiscountPercentage: (percentage: number): string => `${percentage}%`,
    currentDiscountExpiresAt: 'Discount Expires At',
    afterDiscountExpiresAt: 'Once the current discount expires, the subsequent highest available discount, if applicable, will be implemented.',
  },
  mainPage: {
    overviewTitle: 'Technician Dashboard',
    overviewDescription: '',
    inviteUser: 'Invite User',
    manageProviders: 'Manage Providers',
    manageUsers: 'Manage Users',
    viewSupportTickets: 'View Support Tickets',
    viewQueueData: 'View Queue Data',
    manageFeatureFlags: 'Manage Feature Flags',
    extras: 'Extras',
    escalate: 'Escalate',
    pullStats: 'Pull Stats',
  },
  manageProvider: {
    overviewTitle: 'Manage Provider',
    overviewDescription: 'Manage an existing PocketRN provider.',
    updateSuccess: 'Successfully updated!',
    providerId: 'Provider Id',
    providerName: 'Provider Name',
    uniqueNote: 'Must be unique',
    callTypes: 'Call Types',
    manageTranslations: 'Set Translations',
    manageOperationHours: 'Operation Hours',
    inviteCode: 'Invite Code',
    credit: 'Credit',
    discount: 'Discount',
    providerNameAlreadyExists: 'A provider already exists with this name.',
    update: 'Update',
    cannotFindProvider: 'Cannot find a provider with the given id.',
  },
  manageProviders: {
    overviewTitle: 'Manage Providers',
    overviewDescription: 'View existing providers and their offered services or create new providers.',
    viewProvider: 'View Provider',
    provider: {
      label: 'Provider',
      placeholder: 'Select Provider',
    },
    createProvider: 'Create Provider',
  },
  manageFeatureFlags: {
    overviewTitle: 'Manage Feature Flag',
    overviewDescription: 'Managing existing feature flags or create new ones, including scoped flags for a specific provider or user.',
    viewFeatureFlag: 'View Feature Flag',
    featureFlag: {
      label: 'Feature Flag',
      placeholder: 'Select Feature Flag',
    },
    createFeatureFlag: 'Create Feature Flag',
  },
  manageProviderCallTypes: {
    overviewTitle: 'Manage Provider Call Types',
    overviewDescription: (providerName: string): string => `
      Below are the possible call type topics the professionals associated with **$[${providerName}]$** can choose to offer patients and caregivers.

      By checking a call type, you enable it to be selected as an appointment topic.  You may use the existing library
      of predefined call types or create custom call types specific to $[${providerName}]$.
    `,
    definedCallTypes: {
      title: 'Predefined Call Types',
    },
    customCallTypes: {
      title: 'Custom Call Types',
      add: 'Add Custom Call Type',
      duplicate: 'Duplicate',
      edit: 'Edit',
      delete: 'Delete',
      preview: 'Preview',
      deleteModal: `
        ### Are you sure you want to delete this custom call type?

        If you remove this custom call type, any existing scheduled meeting of this call type will show an "Unknown" call type.
      `,
    },
    previewCallType: {
      title: 'Preview of Call Type',
      patientView: 'Patient\'s View',
      nurseInstuctions: 'Instructions for Nurse',
      noInstructions: 'No instructions provided.',
      other: 'Other Details',
      appointmentSlotLength: 'Appointment Duration',
      id: 'ID',
      appointmentBufferLength: 'Additional Buffer',
      appointmentMinutes: (minutes: number): string => `${minutes} minutes`,
      recommendedFirstVisit: 'Recommended for First Visit',
      recommendedAllVisits: 'Recommended for All Visits',
      fulfilledAsAccountTypes: 'Selectable by',
      tags: 'Tags',
    },
    editCallType: {
      title: {
        edit: 'Edit Call Type',
        add: 'Add Call Type',
      },
      form: {
        english: 'Translations for English',
        title: 'Title',
        description: 'Description',
        instructions: 'Nurse Instructions (optional)',
        properties: 'Properties',
        imageSrc: 'Image',
        appointmentSlotLength: 'Appointment Duration (minutes)',
        appointmentBufferLength: 'Additional Buffer (minutes)',
        appointmentMinLength: 'Minimum Duration (minutes) (optional)',
        requiresVideo: 'Requires Video',
        recommendedFirstVisit: 'Recommended for First Visit',
        recommendedAllVisits: 'Recommended for All Visits',
        fulfilledAsAccountTypes: 'Selectable by',
        tags: 'Tags',
        minMoreThanSlotLength: 'Must be less than or equal to the appointment duration',
        fulfilledAsAccountTypesMissingError: 'Must select at least one account type',
        tagsMissingError: 'Must select at least one tag',
      },
    },
  },
  manageTranslations: {
    overviewTitle: 'Set Translations',
    overviewDescription: 'Below are the custom translations you can set for your users for different areas of the app that are specific to your needs.',
    redirectServicesEN: { label: 'Redirect Services (English)' },
  },
  manageOperationHours: {
    overviewTitle: 'Operation Hours',
    overviewDescription: (providerId: string): string => `Below are the operation hours of $[${providerId}]$.`,
    form: {
      timeZoneLabel: 'TimeZone',
      timeZoneSelect: (timeZone: string): string => timeZone,
    },
  },
  manageInviteCode: {
    overviewTitle: 'Invite Code',
    overviewDescription: (providerName: string): string => `Below is the invite code, URL, and QR code to allow people to join **$[${providerName}]$**.`,
    inviteCode: 'Invite Code',
    generateCode: 'Generate',
    setCode: 'Set Code',
    setCodeError: (code: string): string => `The invite code $[${code}]$ is already in use. Please choose or generate a new one.`,
    url: (url: string): string => `URL: [${url}](${url})`,
  },
  manageCredit: {
    overviewTitle: 'Credit',
    overviewDescription: (providerName: string): string => `Below is the subscription credit for **$[${providerName}]$**.`,
    defaultCreditDays: 'Default Trial Days',
    defaultProductKeys: 'Default Trial Plan',
    invalidCreditDaysValue: 'Trial days must be a nonnegative number',
    productKeys: {
      monthlyV1: 'Monthly',
      yearlyV1: 'Yearly',
    },
    providerCreditUpdateNote: 'Apply trial updates to all existing active patients for this provider',
    patientsTrialUpdateNote: 'Updating the provider\'s default credit info is only applicable to active patients and will not terminate any existing patients with valid credit.',
  },
  manageDiscount: {
    overviewTitle: 'Discount',
    overviewDescription: (providerName: string): string => `Below is the subscription discount for **$[${providerName}]$**.`,
    defaultDiscountAmount: 'Default Discount Amount',
    defaultDiscountPercentage: 'Or Default Discount Percentage',
    providerDiscountUpdateNote: 'Apply discount updates to all existing active patients for this provider',
    invalidDiscountValue: 'Value must be a nonnegative number',
    excessiveDiscountAmount: (regularPrice: number): string => `Value cannot exceed the regular subscription price of $${regularPrice}`,
    excessiveDiscountPercentage: 'Value cannot exceed 100%',
    patientsDiscountUpdateNote: 'Updating the provider\'s default discount info is only applicable to patients and will not terminate any existing patients with valid discounts.',
  },
  pullStats: {
    overviewTitle: 'Pull Stats',
    overviewDescription: `
      Pull stats for a particular date range then download the csv files.
      
      **Do not use unless explicitly given permission to. These calls can be very expensive.**
    `,
    form: {
      pullStats: 'Pull Stats',
      pullStart: 'Start Date',
      pullEnd: 'End Date',
      timeZone: {
        label: 'Time Zone',
        option: {
          utc: 'UTC (Default)',
          local: 'Local Time Zone',
          central: 'Central Time Zone',
          eastern: 'Eastern Time Zone',
          mountain: 'Mountain Time Zone',
          pacific: 'Pacific Time Zone',
        },
      },
      records: (records: number): string => records === 1 ? `${records} record` : `${records} records`,
    },
  },
  technicianExtras: {
    overviewTitle: 'Technician Extras',
    overviewDescription: 'Extra tooling specific to technician needs',
    sendTextAsCallCenter: {
      header: 'Send a text to a customer using our call center number.',
      sendTextAsCallCenterSuccess: 'Text sent!  Watch the #bot-support-alerts Slack channel for responses.',
      sendTextAsCallCenterError: 'Unable to send the text.',
      customerPhone: 'Customer #',
      sendText: 'Send',
      message: { label: 'Text Message' },
    },
  },
  viewQueueData: {
    overviewTitle: 'Queue Data',
    overviewDescription: 'The current state of the queue.',
    acceptors: 'Acceptors',
    requestors: 'Requestors',
    meetings: 'Meetings',
    meeting: {
      hasNotAccepted: 'Match Found',
      hasAccepted: 'Premeeting',
      hasStarted: 'In Meeting',
      requestor: 'Requestor',
      acceptor: 'Acceptor',
      escalate: 'Escalate',
    },
    card: {
      fullName: 'Full Name',
      email: 'Email',
      callTypes: 'Call Types',
      blocked: 'Blocked Ids',
      providerId: 'Provider Id',
      region: 'Region',
      licenses: 'Licenses',
    },
  },
  viewSupportTickets: {
    overviewTitle: 'View Support Tickets',
    overviewDescription: 'Enter the Support Ticket Id to view ticket.',
    form: {
      label: 'Ticket Id',
      placeholder: 'SP_1A2b3C4de5',
      getTicketButton: 'Get Ticket',
      errorMessage: 'Ticket Id not found.',
    },
    card: {
      title: (id: string): string => `**Ticket #$[${id}]$**`,
      name: 'Name',
      email: 'Email',
      messageType: 'Message Type',
      message: 'Message',
    },
  },
  impersonationBanner: {
    content: 'You are currently impersonating a user. Click the logout button to return to your own account.',
    logout: 'Logout',
  },
  featureFlagTestBanner: { passThrough: (value: string): string => value },
};

export const locales = { technician };
