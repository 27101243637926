import { Person } from '@pocketrn/entities/dist/community';
import { LinkedUser, PhoneNotificationPreference, User } from '@pocketrn/entities/dist/core';

export interface UserDraft {
  user: User;
  person: Person;
  providerId?: string;
  accountOwner: {
    type: AccountOwner;
    email?: string;
    phone?: string;
    uid?: string;
    firstName?: string;
    lastName?: string;
    preferredName?: string;
    phoneNotificationPreference?: PhoneNotificationPreference;
    isPowerOfAttorney?: boolean;
    linkedUser?: LinkedUser;
  };
  consentSignViaLink?: {
    email?: string;
    phone?: string;
  };
}

export enum AccountOwner {
  Caller = 'caller',
  Other = 'other',
  Self = 'self',
  Unknown = 'unknown',
}
