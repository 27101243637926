// @NOTE: This is the the date when it was most recently updated, if you make any legal changes to the content be sure to update this date
export const termsOfServiceDate = '2022-10-06';

export const termsOfServiceContent = `
Please read these Terms of Service carefully because
they govern your use of the websites located at [https://pocketrn.com](https://pocketrn.com) or [https://pocketrn.dev](https://pocketrn.dev)
(collectively, the “Site”) and the virtual nursing services (“Virtual Visits”) accessible via the
Site offered by Pocket RN, Inc. (“PocketRN”). PocketRN provides Virtual Visits through
affiliated registered nurses and other licensed professionals (collectively, the “Professionals”).
To make these Terms easier to read, the Site and our services, including Virtual Visits are
collectively called the “Services.”

**IMPORTANT NOTICE REGARDING ARBITRATION FOR U.S. CUSTOMERS:** WHEN YOU
AGREE TO THESE TERMS YOU ARE AGREEING (WITH LIMITED EXCEPTION) TO RESOLVE
ANY DISPUTE BETWEEN YOU AND POCKETRN THROUGH BINDING, INDIVIDUAL
ARBITRATION RATHER THAN IN COURT. PLEASE REVIEW CAREFULLY **SECTION** **18**
**“DISPUTE RESOLUTION”** BELOW FOR DETAILS REGARDING ARBITRATION.
USE OF THE SERVICES IS NOT FOR EMERGENCIES. IF YOU THINK YOU HAVE A MEDICAL
EMERGENCY, CALL 911 OR GO TO THE NEAREST OPEN CLINIC OR EMERGENCY ROOM.
THE SERVICES MIGHT NOT BE APPROPRIATE FOR ALL MEDICAL CONDITIONS OR
CONCERNS.

1. **Agreement to Terms**. By using our Services, you agree to be bound by these Terms. If
you don't agree to be bound by these Terms, do not use the Services.

2. **Privacy Policy**. Please review our Privacy Policy, which also governs your use of the
Services, for information on how we collect, use and share your information.

3. **Changes to these Terms or the Services.** We may update the Terms from time to time
in our sole discretion. If we do, we'll let you know by posting the updated Terms on the Site
and/or may also send other communications. It's important that you review the Terms
whenever we update them or you use the Services. If you continue to use the Services after
we have posted updated Terms it means that you accept and agree to the changes. If you
don't agree to be bound by the changes, you may not use the Services anymore. Because
our Services are evolving over time we may change or discontinue all or any part of the
Services, at any time and without notice, at our sole discretion.

4. **Who May Use the Services?**
You may use the Services only if you are 18 years or older and capable of forming a binding
contract with PocketRN, and not otherwise barred from using the Services under applicable
law.

5. **Your Relationship with PocketRN.**
PocketRN is a technology company that makes available certain products and services to
enable Virtual Visits. In connection with the Services, PocketRN provides certain
technological and other administrative support for or is otherwise affiliated with
professional practices (“Practices”) that employ or contract with Professionals.
PocketRN acts solely as a technology platform to provide you with products and services
offered through the Services, including connecting you with Practices and the Professionals.
We do not control or interfere with the practice of medicine, nursing or other licensed
professional services by Practices or any Professionals, each of whom is solely responsible
for the medical care and treatment he or she provides to you. By accepting these Terms, you
acknowledge and agree that PocketRN is not a healthcare provider and that by using the
Services, you are not entering into a doctor-patient or other healthcare provider-patient
relationship with PocketRN. By using the Services, you may, however, be entering into a
doctor-patient or other health care provider-patient relationship with a Practice and/or one
or more Professionals.

By accepting the Terms, you understand that, Practices and/or Professionals may send you
messages, reports, and emails via the Services regarding your diagnosis and/or treatment.
You further understand and agree that it is your responsibility to monitor and respond to
these messages, reports, and emails and that neither PocketRN nor the Practices or any
Professional will be responsible in any way, and you will not hold PocketRN, any Practice or
any Professional liable for any loss, injury, or claims of any kind resulting from your failure to
read or respond to these messages or for your failure to comply with any treatment
recommendations or instructions from the Practice or your Professional(s).
While you are not establishing a doctor-patient or other health care provider-patient
relationship with PocketRN, by using the Services, you are establishing a direct customer
relationship with PocketRN to use the products and services made available through the
Services. In connection with such relationship, you may provide to us, or cause to be
provided to us on your behalf, personal information, including health information, that is
subject to use by us in accordance with our Privacy Policy.

6. **Disclosures Regarding Content and Virtual Visits.**

(a) The content of the Site are provided for informational purposes only. Any advice or
information provided through the content on the Site does not create a provider-patient
relationship, is not an aid to making medical decisions, and is not marketed, promoted or
otherwise intended to be used to diagnose any disease or other condition, or to cure,
mitigate, treat, or prevent any disease. The content of the Site is not a substitute for
professional medical advice, diagnosis or treatment. Always seek the advice of your
physician or other qualified health provider with any questions you may have regarding your
health. Never disregard professional medical advice or delay in seeking it because of
something you have read on the Site or because of information provided through the
Services. If you think you may have a medical emergency, call your doctor or 911
immediately. RELIANCE ON ANY CONTENT PROVIDED ON THE SITE BY POCKETRN,
OTHERS APPEARING ON THE SITE AT THE INVITATION OF POCKETRN, OR OTHER USERS
OF THE SITE IS SOLELY AT YOUR OWN RISK.

(b) The Virtual Visits you receive from Professionals through the Site should not be used
as a replacement for professional guidance from your primary care provider or any other
health care providers, and such Virtual Visits may not necessarily give rise to a
provider-patient relationship or an ongoing treatment relationship. You are expected to seek
follow-up or emergency care as needed or recommended by a Professional, and you should
continue to consult with your primary care provider and/or any other health care providers
as needed or recommended. Additionally, unexpected events may disrupt our electronic
communication with you and we may not be able to contact you as quickly as we would like
to due to uncontrollable circumstances. If you think you may have a medical emergency, call
your doctor or 911 immediately. Moreover, please be aware that our Professionals tailor their
response to your specific situation, taking into account the information you have shared, and
their response may not be appropriate for other similarly situated patients. The
Professionals who provide the Virtual Visits may make recommendations and/or
arrangements for follow-up care, as appropriate, and you are welcome to access our
Services for these follow-up visits. While the Services are intended to provide benefits such
as convenient on-demand virtual access to Professionals and the Services they provide,
there do exist potential risks associated with the use of the Services, which are outlined in
the Informed Consent to Treatment provision herein.

(c) All Professionals performing Virtual Visits through the Site are registered nurses or
hold other licenses or certifications applicable to provide nursing services that are issued by
the applicable professional licensing or certification board in the state where you received
the Virtual Visits. Some performing Virtual Visits through the Site hold other or higher
professional licenses that were issued by a professional licensing board in the state in which
they practice. You acknowledge that the Professionals that render medical care to you
through the Service may be engaged directly by PocketRN or by professional practices and
other medical care organizations that are affiliated with PocketRN, and in connection with
such affiliation, PocketRN and/or such medical group may have a financial interest in each
other. Any information or advice received from a Professional comes from the Professional
and PocketRN is not responsible for the quality and appropriateness of any care that the
Professional renders to you. While PocketRN may facilitate your selection of and
communications with the Professional, it is the Professional providing the medical services
or medical advice, and any provider-patient relationship is only between you and the
applicable Professional, and not with PocketRN. You can report a complaint relating to any
Services provided by a Professional by contacting the professional licensing board in the
state where you received the Virtual Visit, or by contacting PocketRN directly. In a
professional relationship, sexual intimacy is never appropriate and should be reported to the
professional licensing board that licenses, registers or certifies the licensee. Any clinical
records created as a result of your use of the Site and the Virtual Visits will be securely
maintained by PocketRN and/or its service providers on behalf of the Professional with
whom you consult, for a period that is no less than the minimum number of years that such
records are required to be maintained under applicable state and federal law.

(d) PocketRN offers virtual registered nursing services provided by Professionals to Site
users. The terms “virtual care” or “virtual visit” or “telehealth” or “telemedicine” generally refer to healthcare
services that are provided to a patient in one location by a professional in a distant location
facilitated through the use of some form of telecommunications technology, such as
synchronous, interactive audio-video interfaces and/or asynchronous store-and-forward
modalities. Telehealth technologies may also involve the electronic transmission of your
medical records, images, personal health information, or other data between you and a
health care provider. The information exchanged between our Professionals and you may be
used for diagnosis, therapy, treatment, follow-up care, consultation, education, care
management and/or self-management of your health care.

(e) As with any type of health care services, there are potential risks associated with the
use of virtual care services, including the Virtual Visits provided through the Site:

. Information available to the Professionals may not be sufficient to make a correct diagnosis or other professional decisions. For example, there could be limitations in the information transmitted to the Professional and access to your complete medical records, which could lead to incorrect assessments or other adverse reactions. Also, information that can be obtained only by in-person, physical examination or by being physically present with the patient will not be available. In some cases, the Professional may conclude that the information transmitted is not sufficient (e.g., poor resolution of images) or on some other basis the nature of your issue is such that it does not allow for the Services to be provided appropriately by the Professional without an in-person evaluation.
. Telehealth and virtual care relies on electronic communications and devices. Any technical failure or power outage could therefore delay or disrupt such communications and hinder, delay or erase PocketRN's and its Professionals' ability to assist you. These limitations could result in incorrect assessment or diagnosis, which in turn could lead to care that is not helpful, or that could be harmful or cause other problems. Professionals performing Virtual Visits through the Site are aware of these limitations and take them into account in making professional decisions within the scope of their practice as a registered nurse, but the risk of error nevertheless exists.
. Although the electronic systems we use incorporate network and software security protocols to protect the privacy and security of health information, those protocols could fail, and there could be a breach of privacy of your health information. Unfortunately, the transmission of information via the internet is not completely secure. Although we take commercially reasonable steps designed to protect your personal information, we cannot guarantee the security of your personal information transmitted to, or through, our Services. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures employed by the Services. Please see our [Privacy Policy](https://pocketrn.com/policies/privacy), and our [Notice of Privacy Practices](https://www.pocketrn.com/policies/notice-of-privacy-practices)
. In some cases, applicable laws may prevent Professionals from providing the Services you desire through the Site. When that is the case, the Professional will refer you to an appropriate provider who can provide the services you desire.
. Since telehealth and other forms of virtual care remain relatively new approaches to care, risks not yet identified, possibly significant, could also exist. You are under no obligation, of course, to obtain virtual care. You may obtain care through conventional, in-person services instead of or in addition to these Services. Please feel free to provide feedback to PocketRN should you become concerned that our virtual care may be insufficient for your needs.

7. **Limited Use and Availability**
Our Services are currently only available to individuals who are located in the United States and in
order to use the Service, you must be located in one of those states. Our Services may be
subject to state regulations and may change from time to time due to changes in applicable
regulatory requirements.

In some cases, the Services may not be the most appropriate way for you to track or
manage your health and wellness, or provide information to, communicate with or seek
medical care and treatment from a healthcare provider. For example, certain medical
conditions may require an in-person procedure or a healthcare provider other than your
Professional, or your Professional may determine that your diagnosis and/or treatment
require an in-person office visit or are otherwise not appropriately addressed through use of
the Services. In such a case, you may receive notification that you will be unable to use the
Service for the particular issue you submitted and may be provided with additional
information regarding next steps.

8. **Feedback.** We appreciate feedback, comments, testimonials, ideas, proposals and
suggestions for improvements to the Services (“Feedback”). If you choose to submit
Feedback, you agree that we are free to use it without any restriction or compensation to
you. PocketRN will not publish your name or other identifying information in connection with
any testimonial unless you have given us your prior approval. PocketRN may republish any
publicly available review, comment, or testimonial about PocketRN or the Services on the
Site or in other media.

9. **Your Content.**

(a) User Content. Our Services may allow you to submit, store, or share information,
data, or content such as text (in posts or communications with Professionals or others), files,
assessments, self-report measures, lab samples, laboratory results, prescriptions and
pharmaceutical documents, medical records, documents, graphics, images, music, software,
audio and video. Anything (other than Feedback) that you post or otherwise make available
through the Services is referred to as “User Content.” PocketRN does not claim any
ownership rights in any User Content and nothing in these Terms will be deemed to restrict
any rights that you may have to your User Content.

(b) Permissions to Your User Content. By making any User Content available through the
Services you hereby grant to PocketRN a non-exclusive, transferable, worldwide,
royalty-free license, with the right to sublicense, to use, copy, modify, create derivative works
based upon, distribute, publicly display, and publicly perform your User Content in
connection with operating, improving and providing the Services.

(c) Your Responsibility for User Content. You are solely responsible for all your User
Content. You represent and warrant that you have (and will have) all rights that are
necessary to grant us the license rights in your User Content under these Terms. You
represent and warrant that neither your User Content, nor your use and provision of your
User Content to be made available through the Services, nor any use of your User Content
by PocketRN on or through the Services will infringe, misappropriate or violate a third
party's intellectual property rights, or rights of publicity or privacy, or result in the violation of
any applicable law or regulation.

(d) Removal of User Content. You can remove your User Content by specifically deleting
it. You should know that in certain instances, some of your User Content (such as posts or
comments you make) may not be completely removed and copies of your User Content may
continue to exist on the Services. Even upon termination of your account, PocketRN may
retain your medical records, which may not be removed from the PocketRN system/platform.
To the maximum extent permitted by law, we are not responsible or liable for the removal or
deletion of (or the failure to remove or delete) any of your User Content.

(e) PocketRN's Intellectual Property. We may make available through the Services
content that is subject to intellectual property rights. We retain all rights to that content.

10. **Service Text Messages.** If you provide your cellular phone number to PocketRN (either
online or via text message) and agree to receive communications from PocketRN, you
specifically authorize PocketRN to send text messages or calls to your phone. You are not
required to consent to receive text messages or calls as a condition of filling your
prescriptions and may opt out of such messages. You can opt out of receiving text messages
by responding “STOP” to any text message you receive from PocketRN.

11. **General Prohibitions and PocketRN's Enforcement Rights.** You agree not to do any of
the following:

(a) Post, upload, publish, submit or transmit any User Content that:

(i) infringes, misappropriates or violates a third party's patent, copyright, trademark, trade secret, moral rights or other intellectual property rights, or rights of publicity or privacy;
(ii) violates, or encourages any conduct that would violate, any applicable law or regulation or would give rise to civil liability;
(iii) is fraudulent, false, misleading or deceptive;
(iv) is defamatory, obscene, pornographic, vulgar or offensive;
(v) promotes discrimination, bigotry, racism, hatred, harassment or harm against any individual or group;
(vi) is violent or threatening or promotes violence or actions that are threatening to any person or entity;
(vii) promotes illegal or harmful activities or substances;

(b) Use, display, mirror or frame the Services or any individual element within the
Services, PocketRN's name, any PocketRN trademark, logo or other proprietary information,
or the layout and design of any page or form contained on a page, without PocketRN's
express written consent;

(c) Access, tamper with, or use non-public areas of the Services, PocketRN's computer
systems, or the technical delivery systems of PocketRN's providers;

(d) Attempt to probe, scan or test the vulnerability of any PocketRN system or network or
breach any security or authentication measures;

(e) Avoid, bypass, remove, deactivate, impair, descramble or otherwise circumvent any
technological measure implemented by PocketRN or any of PocketRN's providers or any
other third party (including another user) to protect the Services;

(f) Attempt to access or search the Services or download content from the Services
using any engine, software, tool, agent, device or mechanism (including spiders, robots,
crawlers, data mining tools or the like) other than the software and/or search agents
provided by PocketRN or other generally available third-party web browsers;

(g) Send any unsolicited or unauthorized advertising, promotional materials, email, junk
mail, spam, chain letters or other form of solicitation;

(h) Use any meta tags or other hidden text or metadata utilizing a PocketRN trademark,
logo URL or product name without PocketRN's express written consent;

(i) Use the Services, or any portion thereof, for any commercial purpose or for the benefit
of any third party or in any manner not permitted by these Terms;

(j) Forge any TCP/IP packet header or any part of the header information in any email or
newsgroup posting, or in any way use the Services to send altered, deceptive or false
source-identifying information;

(k) Attempt to decipher, decompile, disassemble or reverse engineer any of the software
used to provide the Services;

(l) Interfere with, or attempt to interfere with, the access of any user, host or network,
including, without limitation, sending a virus, overloading, flooding, spamming, or
mail-bombing the Services;

(m) Collect or store any personally identifiable information from the Services from other
users of the Services without their express permission;

(n) Impersonate or misrepresent your affiliation with any person or entity;

(o) Stalk or harass any PocketRN employee or Professional either through or outside
your use of the Services;

(p) Report false or fictitious emergencies, whether relating to yourself or others;

(q) Violate any applicable law or regulation;

(r) Encourage or enable any other individual to do any of the foregoing.

PocketRN is not obligated to monitor access to or use of the Services or to review or edit any
content. However, we have the right to do so for the purpose of operating the Services, to
ensure compliance with these Terms and to comply with applicable law or other legal
requirements. We reserve the right, but are not obligated, to remove or disable access to any
content, including User Content, at any time and without notice, including, but not limited to,
if we, at our sole discretion, consider it objectionable or in violation of these Terms. We have
the right to investigate violations of these Terms or conduct that affects the Services. We
may also consult and cooperate with law enforcement authorities to prosecute users who
violate the law.

12. **Links to Third Party Websites or Resources.** The Services may allow you to access
third-party websites or other resources. We provide access only as a convenience and are
not responsible for the content, products or services on or available from those resources or
links displayed on such websites. You acknowledge sole responsibility for and assume all
risk arising from, your use of any third-party resources.

13. **Termination.** We may suspend or terminate your access to and use of the Services,
including suspending access to or terminating your account, at our sole discretion, at any
time and without notice to you. You may cancel your account at any time by sending us an
email at support@pocketrn.com or canceling via the account management page of the Site,
whereupon PocketRN will remove your access to the PocketRN platform and Services, and
PocketRN will cease proactively contacting you. However, PocketRN may retain your
medical records on the PocketRN system/platform. Upon any termination, discontinuation or
cancellation of the Services or your account, the following **Sections** will survive: **9(b)**, **9(c)**,
**9(e)**, **10**, **13**, **14**, **15**, **16**, **17**, **18**, and **19**.

14. **Warranty Disclaimers.** THE SERVICES ARE PROVIDED “AS IS,” WITHOUT
WARRANTY OF ANY KIND. WITHOUT LIMITING THE FOREGOING, WE EXPLICITLY
DISCLAIM ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
PARTICULAR PURPOSE, QUIET ENJOYMENT AND NON-INFRINGEMENT, AND ANY
WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE. General
advice posted to the Site or relayed through the Services is for informational purposes and is
not intended to replace or substitute any medical service or specific professional advice,
including those PocketRN and its Professionals may provide to you as Virtual Visits. We
make no warranty that the Services will meet your requirements or be available on an
uninterrupted, secure, or error-free basis. We make no warranty regarding the quality,
accuracy, timeliness, truthfulness, completeness or reliability of any information or content
on the Services.

15. **Indemnity.** You will indemnify and hold PocketRN and its officers, directors,
employees and agents, harmless from and against any claims, disputes, demands, liabilities,
damages, losses, and costs and expenses, including, without limitation, reasonable legal
and accounting fees arising out of or in any way connected with: (a) your access to or use of
the Services (b) your User Content (c) your violation of these Terms.

16. **Limitation of Liability.**

(a) TO THE MAXIMUM EXTENT PERMITTED BY LAW, NEITHER POCKETRN NOR ITS
SERVICE PROVIDERS INVOLVED IN CREATING, PRODUCING, OR DELIVERING THE
SERVICES, INCLUDING THE VIRTUAL VISITS, WILL BE LIABLE FOR ANY INCIDENTAL,
SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, OR DAMAGES FOR LOST
PROFITS, LOST REVENUES, LOST SAVINGS, LOST BUSINESS OPPORTUNITY, LOSS OF
DATA OR GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE OR SYSTEM
FAILURE OR THE COST OF SUBSTITUTE SERVICES OF ANY KIND ARISING OUT OF OR IN
CONNECTION WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO USE THE
SERVICES, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING
NEGLIGENCE), PRODUCT LIABILITY, PROFESSIONAL MALPRACTICE OR ANY OTHER
LEGAL THEORY, AND WHETHER OR NOT POCKETRN OR ITS SERVICE PROVIDERS HAVE
BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, EVEN IF A LIMITED REMEDY
SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.

(b) TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT WILL POCKETRN'S
TOTAL LIABILITY ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM
THE USE OF OR INABILITY TO USE THE SERVICES EXCEED THE AMOUNTS YOU HAVE
PAID OR ARE PAYABLE BY YOU TO POCKETRN FOR USE OF THE SERVICES OR ONE
HUNDRED DOLLARS ($100), IF YOU HAVE NOT HAD ANY PAYMENT OBLIGATIONS TO
POCKETRN, AS APPLICABLE.

(c) THE EXCLUSIONS AND LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE
FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN POCKETRN AND
YOU.

17. **Governing Law and Forum Choice**. These Terms and any action related thereto will
be governed by the Federal Arbitration Act, federal arbitration law, and the laws of the State
of California, without regard to its conflict of laws provisions. Except as otherwise expressly
set forth in **Section** **18** **“Dispute Resolution”** the exclusive jurisdiction for all Disputes
(defined below) that you and PocketRN are not required to arbitrate will be the state and
federal courts located in Santa Clara County, and you and PocketRN each waive any
objection to jurisdiction and venue in such courts.

18. **Dispute Resolution.**

(a) Mandatory Arbitration of Disputes. We each agree that any dispute, claim or
controversy arising out of or relating to these Terms or the breach, termination, enforcement,
interpretation or validity thereof or the use of the Services (collectively, “Disputes”) will be
resolved solely by binding, individual arbitration and not in a class, representative or
consolidated action or proceeding. You and PocketRN agree that the U.S. Federal Arbitration
Act governs the interpretation and enforcement of these Terms, and that you and PocketRN
are each waiving the right to a trial by jury or to participate in a class action. This arbitration
provision shall survive termination of these Terms.

(b) Exceptions. As limited exceptions to **Section** **18(a)** above: (i) we both may seek to
resolve a Dispute in small claims court if it qualifies; and (ii) we each retain the right to seek
injunctive or other equitable relief from a court to prevent (or enjoin) the infringement or
misappropriation of our intellectual property rights.

(c) Conducting Arbitration and Arbitration Rules. The arbitration will be conducted by
the American Arbitration Association (“AAA”) under its Consumer Arbitration Rules (the
“AAA Rules”) then in effect, except as modified by these Terms. The AAA Rules are available
at [www.adr.org](www.adr.org) or by calling 1-800-778-7879. A party who wishes to start arbitration must
submit a written Demand for Arbitration to AAA and give notice to the other party as
specified in the AAA Rules. The AAA provides a form Demand for Arbitration at
[www.adr.org](www.adr.org).
Any arbitration hearings will take place in the county (or parish) where you live, unless we
both agree to a different location. The parties agree that the arbitrator shall have exclusive
authority to decide all issues relating to the interpretation, applicability, enforceability and
scope of this arbitration agreement.

(d) Arbitration Costs. Payment of all filing, administration and arbitrator fees will be
governed by the AAA Rules, and we won't seek to recover the administration and arbitrator
fees we are responsible for paying, unless the arbitrator finds your Dispute frivolous. If we
prevail in arbitration we'll pay all of our attorneys' fees and costs and won't seek to recover
them from you. If you prevail in arbitration you will be entitled to an award of attorneys' fees
and expenses to the extent provided under applicable law.

(e) Injunctive and Declaratory Relief. Except as provided in **Section** **18(b)** above, the
arbitrator shall determine all issues of liability on the merits of any claim asserted by either
party and may award declaratory or injunctive relief only in favor of the individual party
seeking relief and only to the extent necessary to provide relief warranted by that party's
individual claim. To the extent that you or we prevail on a claim and seek public injunctive
relief (that is, injunctive relief that has the primary purpose and effect of prohibiting unlawful
acts that threaten future injury to the public), the entitlement to and extent of such relief
must be litigated in a civil court of competent jurisdiction and not in arbitration. The parties
agree that litigation of any issues of public injunctive relief shall be stayed pending the
outcome of the merits of any individual claims in arbitration.

(f) Class Action Waiver. YOU AND POCKETRN AGREE THAT EACH MAY BRING CLAIMS
AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A
PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE
PROCEEDING. Further, if the parties' Dispute is resolved through arbitration, the arbitrator
may not consolidate another person's claims with your claims, and may not otherwise
preside over any form of a representative or class proceeding. If this specific provision is
found to be unenforceable, then the entirety of this Dispute Resolution section shall be null
and void.

(g) Severability. With the exception of any of the provisions in **Section** **18(f)** of these
Terms (“Class Action Waiver”), if an arbitrator or court of competent jurisdiction decides that
any part of these Terms is invalid or unenforceable, the other parts of these Terms will still
apply.

19. **General Terms.**

(a) Reservation of Rights. PocketRN and its licensors exclusively own all right, title and
interest in and to the Services, including all associated intellectual property rights. You
acknowledge that the Services are protected by copyright, trademark, and other laws of the
United States and foreign countries. You agree not to remove, alter or obscure any copyright,
trademark, service mark or other proprietary rights notices incorporated in or accompanying
the Services.

(b) Entire Agreement. These Terms constitute the entire and exclusive understanding
and agreement between PocketRN and you regarding the Services, and these Terms
supersede and replace all prior oral or written understandings or agreements between
PocketRN and you regarding the Services. If any provision of these Terms is held invalid or
unenforceable by an arbitrator or a court of competent jurisdiction, that provision will be
enforced to the maximum extent permissible and the other provisions of these Terms will
remain in full force and effect. Except where provided by applicable law in your jurisdiction,
you may not assign or transfer these Terms, by operation of law or otherwise, without
PocketRN's prior written consent. Any attempt by you to assign or transfer these Terms
absent our consent or your statutory right, without such consent, will be null. PocketRN may
freely assign or transfer these Terms without restriction. Subject to the foregoing, these
Terms will bind and inure to the benefit of the parties, their successors and permitted
assigns.

(c) Notices. Any notices or other communications provided by PocketRN under these
Terms will be given: (i) via email; or (ii) by posting to the Services. For notices made by email,
the date of receipt will be deemed the date on which such notice is transmitted.

(d) Waiver of Rights. PocketRN's failure to enforce any right or provision of these Terms
will not be considered a waiver of such right or provision. The waiver of any such right or
provision will be effective only if in writing and signed by a duly authorized representative of
PocketRN. Except as expressly set forth in these Terms, the exercise by either party of any of
its remedies under these Terms will be without prejudice to its other remedies under these
Terms or otherwise.

20. **Contact Information.** If you have any questions about these Terms or the Services,
please contact PocketRN at support@pocketrn.com.

By signing this document, I acknowledge and agree that I hereby authorize Pocket RN and
any Professional performing Services through the Site to provide care to me virtually in the
course of my engagement and treatment, as applicable.

Further, I agree that my electronic agreement to these Terms is equivalent to the signature of
a patient. I understand a copy of this consent form is available by printing this document or
by request.
`;
