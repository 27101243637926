import { AccountType, Provider, PhoneType, PhoneNotificationPreference, ContactStatus, UserPhoneJson } from '@pocketrn/entities/dist/core';
import { CoreSDK } from '../../services/firebase/CoreSDK';
import { firebaseAuth } from '../../utils/firebase';
import { RegistrationActions } from '../redux/registration/actions';
import { sendEmailVerification } from 'firebase/auth';
import { SessionUserController, SessionUserSDK } from '../../apps/user-state/index';
import { Person } from '@pocketrn/entities/dist/community';
import { StateAbbreviation } from '@pocketrn/localizer';
import { InviteCodeData } from '../../entities/Router';
import {
  sessionUserActions,
  ActionKey as SessionUserActionKey,
} from '../../apps/user-state/src/session/actions';
import { getFullPhone } from '@pocketrn/client/dist/app-utils';
import { logger } from '@pocketrn/client/dist/app-logger';

// @NOTE: Redux does not export its Store type.
export type ReduxStore = any;

export class RegistrationController {
  public sdk: CoreSDK;
  public store: ReduxStore;
  public sessionUserController: SessionUserController;
  public sessionUserSDK: SessionUserSDK;

  constructor(
    sdk: CoreSDK,
    store: ReduxStore,
    sessionUserController: SessionUserController,
    sessionUserSDK: SessionUserSDK,
  ) {
    this.sdk = sdk;
    this.store = store;
    this.sessionUserController = sessionUserController;
    this.sessionUserSDK = sessionUserSDK;
  }

  public async sendVerificationEmail(): Promise<void> {
    const user = firebaseAuth.currentUser;
    if (!user) {
      throw new Error('firebase user is not set');
    }
    if (localStorage.getItem('_testId')) {
      logger.logInfo('Skipping verification email because an E2E test is running');
      return;
    }
    logger.logInfo(`Sending verification email to: ${user.email}`);
    sendEmailVerification(user, {
      // @NOTE: this is the redirect url of the email link
      // @SOURCE: https://firebase.google.com/docs/auth/web/passing-state-in-email-actions#passing_statecontinue_url_in_email_actions
      url: window.location.origin,
    });
  }

  public async sendVerificationPhone(phone: string): Promise<void> {
    await this.sdk.sendVerificationPhone(phone);
  }

  public startRegistration(): void {
    this.store.dispatch(RegistrationActions.setIsRegistering(true));
  }

  public finishRegistration(): void {
    this.store.dispatch(RegistrationActions.setIsRegistering(false));
  }

  public async verifyPhoneCode(phone: UserPhoneJson | string, code: string): Promise<void> {
    let _phone: UserPhoneJson;
    if (typeof phone === 'string') {
      _phone = {
        number: phone,
        type: PhoneType.Mobile,
        notificationPreference: PhoneNotificationPreference.Both,
        status: ContactStatus.Primary,
      };
    } else {
      _phone = phone;
      _phone.status = ContactStatus.Primary;
    }
    _phone.number = getFullPhone(_phone.number);
    await this.sdk.verifyPhoneCode(_phone, code);
    const user = this.sessionUserController.getStoredActiveUser();
    if (!user) {
      return;
    }
    if (!user.phones) {
      user.phones = [];
    }
    user.phones.forEach(p => {
      if (p.status === ContactStatus.Primary) {
        p.status = ContactStatus.Verified;
      }
    });
    const thisPhoneIndex = user.phones.findIndex(p => p.number === _phone.number);
    if (thisPhoneIndex !== -1) {
      user.phones[thisPhoneIndex] = _phone;
    } else {
      user.phones.push(_phone);
    }
    this.sessionUserController.setStoredActiveUser(user);
  }

  public async waitlistRegistration(email: string): Promise<void> {
    await this.sdk.waitlistRegistration(email);
  }

  public async registerPerson(
    person: Person,
    region: StateAbbreviation | undefined,
  ): Promise<void> {
    await Promise.all([
      this.sdk.setPerson(person),
      region ? this.sdk.setActiveRegion(region) : undefined,
    ]);
    this.store.dispatch(sessionUserActions.setActiveEntity(SessionUserActionKey.Person, person));
  }

  public async activateUserAccount(
    providerId: string,
    accountType: AccountType,
    inviteCode: string | undefined,
  ): Promise<void> {
    this.store.dispatch(RegistrationActions.clearInviteCodeData());
    await Promise.all([
      this.sessionUserSDK.activateUserAccount(providerId, accountType, inviteCode),
      this.sessionUserSDK.setActiveAccount(accountType, providerId),
    ]);
  }

  public async joinStateWaitlist(
    name: string,
    email: string,
    message: string,
    state: string,
  ): Promise<void> {
    await this.sdk.joinStateWaitlist(name, email, message, state);
  }

  public setInviteCodeData(inviteCodeData: InviteCodeData): void {
    this.store.dispatch(RegistrationActions.setInviteCodeData(inviteCodeData));
  }

  public async getProviders(
    inviteCode: string | undefined,
    providerId: string | undefined,
  ): Promise<Provider[]> {
    return await this.sdk.getProviders(inviteCode, providerId);
  }
}
