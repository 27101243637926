import {
  Account,
  AccountStatus,
  AccountType,
  StripeProductKey,
  StripeSubscription,
  License,
  UserDiscount,
  SubscriptionDiscount,
  ProviderCoupon,
  UserPhoneJson,
} from '@pocketrn/entities/dist/core';
import { TechnicianSDK, UserPerson } from '../../services/firebase/TechnicianSDK';
import { NotFound } from '@pocketrn/client/dist/entity-sdk';
import { CoreSDK } from '../../../../services/firebase/CoreSDK';
import { ClientCustomCallType } from '@pocketrn/entities/dist/meeting';

// @NOTE: Redux does not export its Store type.
export type ReduxStore = any;

export class TechnicianController {
  public sdk: TechnicianSDK;
  public coreSdk: CoreSDK;
  public store: ReduxStore;

  constructor(sdk: TechnicianSDK, coreSdk: CoreSDK, store: ReduxStore) {
    this.sdk = sdk;
    this.coreSdk = coreSdk;
    this.store = store;
  }

  public async retrieveCustomCallTypes(
    providerId: string,
  ): Promise<ClientCustomCallType[]> {
    const customCallTypes = await this.sdk.getCustomCallTypes(providerId);
    return customCallTypes;
  }

  public async updateCustomCallTypeEnabled(
    callTypeId: string,
    shouldEnable: boolean,
  ): Promise<void> {
    await this.sdk.updateCustomCallTypeEnabled(callTypeId, shouldEnable);
  }

  public async deleteCustomCallType(
    callTypeId: string,
  ): Promise<void> {
    await this.sdk.deleteCustomCallType(callTypeId);
  }

  public async setCustomCallType(
    customCallType: ClientCustomCallType,
  ): Promise<ClientCustomCallType> {
    return await this.sdk.setCustomCallType(customCallType);
  }

  public async retrieveUserByEmail(email: string): Promise<{userPerson: UserPerson}> {
    const userPerson = await this.sdk.getUserByEmail(email);
    if (!userPerson) {
      throw new NotFound(new Error(`No user found with email ${email}`));
    }
    return { userPerson };
  }

  public async retrieveUsersByPhone(phone: string): Promise<{users: UserPerson[],}> {
    const users = await this.sdk.getUsersByPhone(phone);
    if (users.length === 0) {
      throw new NotFound(new Error(`No user found with phone ${phone}`));
    }
    return { users };
  }

  public async retrieveUserByUid(uid: string): Promise<{userPerson: UserPerson}> {
    const userPerson = await this.sdk.getUserByUid(uid);
    if (!userPerson) {
      throw new NotFound(new Error(`No user found with uid ${uid}`));
    }
    return { userPerson };
  }

  public async retrieveUserAccounts(uid: string): Promise<{
    accounts: Account[],
    providerDiscounts: SubscriptionDiscount[],
    userDiscounts: UserDiscount[],
  }> {
    const { accounts, providerDiscounts, userDiscounts } = await this.sdk.getUserAccounts(uid);
    return { accounts, providerDiscounts, userDiscounts };
  }

  public async updateUserAccount(
    uid: string,
    accountType: AccountType,
    providerId: string | undefined,
    changes: {
      status?: AccountStatus,
      queuePriority?: number,
    },
  ): Promise<void> {
    await this.sdk.updateUserAccount(uid, accountType, providerId, changes);
  }

  public async updateUserCredit(
    uid: string,
    providerId: string | undefined,
    accountType: AccountType,
    creditEndsAt: Date,
    creditProductKeys: StripeProductKey[],
  ): Promise<void> {
    await this.sdk.updateUserCredit(
      uid, providerId, accountType, creditEndsAt, creditProductKeys,
    );
  }

  public async updateUserDiscount(
    uid: string,
    providerId: string | undefined,
    accountType: AccountType,
    coupon: ProviderCoupon,
  ): Promise<void> {
    await this.sdk.updateUserDiscount(uid, providerId, accountType, coupon);
  }

  public async sendTextAsCallCenter(message: string, to: string): Promise<void> {
    await this.sdk.sendTextAsCallCenter(message, to);
  }

  public async getUserSubscriptionDetails(uid: string): Promise<StripeSubscription | undefined> {
    return this.sdk.getUserSubscriptionDetails(uid);
  }

  public async setLicenses(uid: string, licenses: License[]): Promise<void> {
    await this.sdk.setLicenses(uid, licenses);
  }

  public async setPhones(impersonatedUid: string, phones: UserPhoneJson[]): Promise<void> {
    await this.coreSdk.setPhones(phones, { impersonatedUid });
  }
}
