import { Action, ReduxAction, SDKActionId, SDKActions } from '@pocketrn/client/dist/entity-sdk';

export const REDUCER_KEY = 'sessionUser';

const ra = new ReduxAction(REDUCER_KEY);

export enum ActionKey {
  Account = 'account',
  Provider = 'provider',
  CustomCallType = 'customCallType',
  User = 'user',
  Person = 'person',
  PromptEmailPasswordReauthentication = 'promptEmailPasswordReauthentication',
}

export const sessionUserActionId = new SDKActionId(ra);
export const sessionUserActions = new SDKActions(sessionUserActionId);

export enum SessionReauthType {
  EmailPassword = 'emailPassword',
  Unknown = 'unknown',
}

export interface PromptReauthenticationAction extends Action {
  reauthType?: SessionReauthType;
  email?: string;
}

export const SessionActionId = { SET_PROMPT_EMAIL_PASSWORD_REAUTHENTICATION: ra.actionType('SET_PROMPT_EMAIL_PASSWORD_REAUTHENTICATION') };

export class SessionActions {
  public static unsetPromptReauthentication(): PromptReauthenticationAction {
    return {
      type: SessionActionId.SET_PROMPT_EMAIL_PASSWORD_REAUTHENTICATION,
      reauthType: undefined,
      email: undefined,
    };
  }

  public static setPromptReauthentication(
    type: SessionReauthType,
    email?: string,
  ): PromptReauthenticationAction {
    return {
      type: SessionActionId.SET_PROMPT_EMAIL_PASSWORD_REAUTHENTICATION,
      reauthType: type,
      email,
    };
  }
}
