import { LocalePrimer } from '@pocketrn/localizer';
import { CameraIcon, StatusBar } from '@pocketrn/rn-designsystem';
import React, { FunctionComponent, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { Meeting } from '@pocketrn/entities/dist/meeting';
import { MeetingRoute, MeetingRouter } from '../../apps/meeting-app';
import { PageStatusBarTickers } from './PageStatusBarTickers';
import { User } from '@pocketrn/entities/dist/core';
import { myInProgressMeetings } from '../../utils/myInProgressMeetings';

interface Props extends RenderProps {
  localePrimer: LocalePrimer;
}

export interface RenderProps {
  queueStartedAt?: Date;
  inProgressMeetings: Meeting[];
  user: User | undefined;
}

function showUseTickStatus(props: RenderProps): boolean {
  const _inProgressMeetings = myInProgressMeetings(props.inProgressMeetings, props.user);
  if (!_inProgressMeetings.length && !props.queueStartedAt) return false;
  const meeting = _inProgressMeetings[0];
  return !!(
    meeting?.startedAt ||
    (meeting?.acceptedAt && !meeting?.startedAt) ||
    props.queueStartedAt
  );
}

export function shouldRender(props: RenderProps): boolean {
  const _inProgressMeetings = myInProgressMeetings(props.inProgressMeetings, props.user);
  if (!_inProgressMeetings.length && !props.queueStartedAt) return false;
  const meeting = _inProgressMeetings[0];
  return !!(
    _inProgressMeetings.length > 1 ||
    meeting?.endedAt ||
    showUseTickStatus(props)
  );
}

const PageStatusBar: FunctionComponent<Props> = (props): ReactElement | null => {
  const navigate = useNavigate();

  if (!shouldRender(props)) return null;

  const goToMeeting = () => {
    if (!meeting) return;
    navigate(MeetingRouter.getMeetingRoute(meeting.id));
  };

  const _inProgressMeetings = myInProgressMeetings(props.inProgressMeetings, props.user);

  const meeting = _inProgressMeetings.length ? _inProgressMeetings[0] : undefined;

  if (_inProgressMeetings.length > 1) {
    return (
      <StatusBar
        icon={<CameraIcon blinking size="sm" />}
        message={props.localePrimer.translate('core.statusBar', 'meetingsAreInProgress')}
        onClick={() => navigate(MeetingRoute.Meetings)}
        countdown={undefined}
        color="success"
        testIdRoot="page-statusbar"
      />
    );
  } else if (meeting?.endedAt) {
    return (
      <StatusBar
        icon={<CameraIcon blinking size="sm" />}
        message={props.localePrimer.translate('core.statusBar', 'ended')}
        onClick={goToMeeting}
        countdown={undefined}
        color="success"
        testIdRoot="page-statusbar"
      />
    );
  } else if (
    // @NOTE: We put all status bars that have useTick or useTimeSince in this component
    // to prevent unncessary React re-renders
    showUseTickStatus(props)
  ) {
    return (
      <PageStatusBarTickers
        localePrimer={props.localePrimer}
        queueStartedAt={props.queueStartedAt}
        meeting={meeting}
      />
    );
  } else {
    throw new Error('PageStatusBar: Invalid state, make sure to update shouldRender as needed');
  }
};

export default PageStatusBar;
