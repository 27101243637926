import {
  Account,
  AccountStatus,
  AccountType,
  ContactStatus,
  LinkedUser,
  Permissions,
  PhoneNotificationPreference,
  PhoneType,
  User,
} from '@pocketrn/entities/dist/core';
import { SessionUserController } from '../../apps/user-state/index';
import { CoreSDK, LinkedUsersMaps } from '../../services/firebase/CoreSDK';
import { Person } from '@pocketrn/entities/dist/community';
import { AccountOwner, UserDraft } from '../../components/pages/invite/steps/userDraft';
import { NotFound, PermissionDenied, PromptReauthenticationAfterLogOut, PromptEmailPasswordReauthentication, ManagedProperty } from '@pocketrn/client/dist/entity-sdk';
import { logger } from '@pocketrn/client/dist/app-logger';

// @NOTE: Redux does not export its Store type.
export type ReduxStore = any;

export class LinkedUserController {
  public sdk: CoreSDK;
  public store: ReduxStore;
  public sessionUserController: SessionUserController;

  constructor(sdk: CoreSDK, store: ReduxStore, sessionUserController: SessionUserController) {
    this.sdk = sdk;
    this.store = store;
    this.sessionUserController = sessionUserController;
  }

  public filterLinkedUsers = (
    linkedUsersMaps: LinkedUsersMaps,
    callerUid: string,
    callerAccountType: AccountType,
  ): LinkedUsersMaps => {
    const callerUidKey = callerAccountType === AccountType.Caregiver
      ? 'linkedWithUid' : 'appliedToUid';
    const links = linkedUsersMaps.linkedUsers.filter(link => link[callerUidKey] === callerUid);
    return {
      linkedUsers: links,
      usersMap: linkedUsersMaps.usersMap,
      personsMap: linkedUsersMaps.personsMap,
      accounts: linkedUsersMaps.accounts,
      providersMap: linkedUsersMaps.providersMap,
      customCallTypesMap: linkedUsersMaps.customCallTypesMap,
    };
  };

  public async getLinkedUsers(managedUid?: string): Promise<LinkedUsersMaps> {
    const emptyResponse = {
      linkedUsers: [],
      usersMap: {},
      personsMap: {},
      accounts: [],
      providersMap: {},
      customCallTypesMap: {},
    };
    const sessionAccount = this.sessionUserController.getStoredActiveAccount();
    if (!sessionAccount) {
      logger.logDebug('No active session account found.  If you are running locally, ensure your user.activeAccountType and user.activeProviderId are set to an existing account.');
      return emptyResponse;
    }
    const managed = managedUid ? {
      uid: managedUid,
      accountType: AccountType.Patient,
    } : undefined;
    try {
      const res = await this.sdk.getLinkedUsers(managed);
      return this.filterLinkedUsers(
        res,
        managedUid ? managedUid : sessionAccount.uid,
        managedUid ? AccountType.Patient : sessionAccount.type,
      );
    } catch (e) {
      if (
        e instanceof NotFound ||
        e instanceof PermissionDenied ||
        e instanceof PromptReauthenticationAfterLogOut ||
        e instanceof PromptEmailPasswordReauthentication
      ) {
        return emptyResponse;
      } else {
        throw e;
      }
    }
  }

  public async searchUser(email: string): Promise<User | undefined> {
    const user = await this.sdk.searchUser(email);
    return user;
  }

  public async inviteUserToLink(
    linkToUid: string,
    inviteType: AccountType.Caregiver | AccountType.Patient,
    providerId: string,
    managed?: ManagedProperty,
  ): Promise<string> {
    return await this.sdk.inviteUserToLink(linkToUid, inviteType, providerId, managed);
  }

  public async createCaregiver(userDraft: UserDraft, managed?: ManagedProperty): Promise<{
    caregiverUid: string,
    accountOwnerLinkedUser: LinkedUser;
  }> {
    const account = new Account(
      '',
      AccountType.Caregiver,
      userDraft.providerId ?? '',
      AccountStatus.Approved,
    );
    const user = new User('');
    user.email = userDraft.accountOwner.email;
    user.phones = userDraft.accountOwner.phone ? [
      {
        number: userDraft.accountOwner.phone,
        notificationPreference: (
          userDraft.accountOwner.phoneNotificationPreference ??
          PhoneNotificationPreference.Both
        ),
        type: PhoneType.Mobile,
        status: ContactStatus.Unverified,
      },
    ] : [];
    const res = await this.sdk.addCaregiver(
      user.json(),
      userDraft.person.json(),
      account.json(),
      managed,
    );
    return {
      caregiverUid: res.caregiver.user.uid,
      accountOwnerLinkedUser: res.caregiver.linkedUser,
    };
  }

  public async createPatient(userDraft: UserDraft): Promise<{
    patientUid: string,
    caregiverUid?: string,
    accountOwnerLinkedUser: LinkedUser;
  }> {
    const account = new Account(
      '',
      AccountType.Patient,
      userDraft.providerId ?? '',
      AccountStatus.Approved,
    );
    let ownerUser: User | undefined;
    if (userDraft.accountOwner.type !== AccountOwner.Caller) {
      ownerUser = new User(userDraft.accountOwner.uid ?? '');
      ownerUser.email = userDraft.accountOwner.email;
      ownerUser.phones = userDraft.accountOwner.phone ? [
        {
          number: userDraft.accountOwner.phone,
          notificationPreference: (
            userDraft.accountOwner.phoneNotificationPreference ??
            PhoneNotificationPreference.Both
          ),
          type: PhoneType.Mobile,
          status: ContactStatus.Unverified,
        },
      ] : [];
    }
    let ownerPerson: Person | undefined;
    if (userDraft.accountOwner.type === AccountOwner.Other) {
      ownerPerson = new Person('');
      ownerPerson.firstName = userDraft.accountOwner.firstName;
      ownerPerson.lastName = userDraft.accountOwner.lastName;
    }
    const res = await this.sdk.addPatient(
      userDraft.user.json(),
      userDraft.person.json(),
      account.json(),
      userDraft.accountOwner.type,
      ownerUser ? {
        user: ownerUser.json(),
        person: ownerPerson?.json(),
      } : undefined,
    );
    return {
      patientUid: res.patient.user.uid,
      caregiverUid: res.caregiver?.user.uid,
      accountOwnerLinkedUser: res.caregiver ? res.caregiver.linkedUser : res.patient.linkedUser,
    };
  }

  public async updateLinkedUser(
    id: string,
    status: AccountStatus,
    managed?: ManagedProperty,
  ): Promise<void> {
    await this.sdk.updateLinkedUser(id, status, managed);
  }

  public async updatePermissions(
    id: string,
    permissions: Permissions,
    managed?: ManagedProperty,
  ): Promise<void> {
    await this.sdk.updatePermissions(id, permissions, managed);
  }
}
