import {
  AccountJson,
  ProviderJson,
  UserJson,
} from '@pocketrn/entities/dist/core';
import { PromptReauthenticationAction, SessionActionId, SessionReauthType, sessionUserActionId } from './actions';
import { SDKEntities, Action, getSDKReducerState } from '@pocketrn/client/dist/entity-sdk';
import { PersonJson } from '@pocketrn/entities/dist/community';
import { ClientCustomCallTypeJson } from '@pocketrn/entities/dist/meeting';

export interface SessionUserState {
  account: SDKEntities<AccountJson>;
  provider: SDKEntities<ProviderJson>;
  customCallType: SDKEntities<ClientCustomCallTypeJson>;
  user: SDKEntities<UserJson>;
  person: SDKEntities<PersonJson>;
  promptReauthentication: {
    email?: string;
    type?: SessionReauthType;
  };
}

const getInitialState = (): SessionUserState => {
  return {
    account: { listEntities: [], mapEntities: {}, loading: false },
    provider: { listEntities: [], mapEntities: {}, loading: false },
    customCallType: { listEntities: [], mapEntities: {}, loading: false },
    user: { listEntities: [], mapEntities: {}, loading: false },
    person: { listEntities: [], mapEntities: {}, loading: false },
    promptReauthentication: {
      email: undefined,
      type: undefined,
    },
  };
};

const initialState = getInitialState();

const sessionUser = (state = initialState, action: Action): SessionUserState => {
  const newState = getSDKReducerState(
    state,
    action,
    sessionUserActionId,
    getInitialState,
  ) as SessionUserState;
  switch (action.type) {
    case SessionActionId.SET_PROMPT_EMAIL_PASSWORD_REAUTHENTICATION:
      return State.setPromptReauthentication(newState, action as PromptReauthenticationAction);
    default:
      return newState;
  }
};

class State {
  public static setPromptReauthentication(
    state: SessionUserState,
    action: PromptReauthenticationAction,
  ): SessionUserState {
    return {
      ...state,
      promptReauthentication: {
        email: action.email,
        type: action.reauthType,
      },
    };
  }
}

export default sessionUser;
