import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import localePrimer from '../../utils/localePrimer';
import {
  Button,
  ButtonGroup,
  Form,
  Markdown,
  Paragraph,
  TextField,
  AutoComplete,
} from '@pocketrn/rn-designsystem';
import { firebaseAuth } from '../../utils/firebase';
import {
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
} from 'firebase/auth';
import formValidator from '../../utils/fv';
import { sessionUserController } from '../../utils/userStateInit';

const locale = 'core.login.auth';
const signUpLocale = `${locale}.signup`;
const logInLocale = `${locale}.login`;

interface Props {
  type: 'login' | 'register';
  email?: string;
  hideEmail?: boolean;
  background: 'white' | 'gray';
  onAuth?: () => void;
}

export const AuthElement: FunctionComponent<Props> = (props): ReactElement => {
  const [ selectedEmailAuth, setSelectedEmailAuth ] = useState(false);
  const _email = props.email || '';
  const [ email, setEmail ] = useState(_email);
  const [ password, setPassword ] = useState('');
  const [ emailError, setEmailError ] = useState('');
  const [ passwordError, setPasswordError ] = useState('');
  const [ loading, setLoading ] = useState(false);
  const [ shouldLoginWithGoogle, setShouldLoginWithGoogle ] = useState(false);
  const [ emailAlreadyInUse, setEmailAlreadyInUse ] = useState(false);
  const [ unexpectedError, setUnexpectedError ] = useState('');
  const [ confirmationMessage, setConfirmationMessage ] = useState('');
  const isRegistering = props.type === 'register';
  const typeSpecificLocale = isRegistering ? signUpLocale : logInLocale;

  const reset = () => {
    setSelectedEmailAuth(false);
    setEmail(_email === email ? _email : '');
    setPassword('');
    setEmailError('');
    setPasswordError('');
    setLoading(false);
    setShouldLoginWithGoogle(false);
    setEmailAlreadyInUse(false);
    setUnexpectedError('');
    setConfirmationMessage('');
  };

  useEffect(() => {
    reset();
  }, [isRegistering]);

  useEffect(() => {
    if (_email && !isRegistering) {
      setEmail(_email);
      setSelectedEmailAuth(true);
    }
  }, [_email]);

  const formFieldsAllValid = (): boolean => {
    const emailValidation = formValidator.validateEmail(email);
    let allValid = true;
    if (emailValidation.error) {
      setEmailError(emailValidation.error);
      allValid = false;
    } else {
      setEmailError('');
    }
    // @NOTE: to support legacy password requires, we have a very loose
    // password validation for logging in versus registering.
    const legacyPasswordLoginSupportOptions = {
      minLength: 6,
      requireLowercase: false,
      requireUppercase: false,
      requireNumber: false,
      requireSpecialCharacter: false,
    };
    const passwordValidation = formValidator.validatePassword(
      password,
      isRegistering ? undefined : legacyPasswordLoginSupportOptions,
    );
    if (passwordValidation.error) {
      setPasswordError(passwordValidation.error);
      allValid = false;
    } else {
      setPasswordError('');
    }
    return allValid;
  };

  const isValidEmailSignInMethod = async () => {
    try {
      const methods = await fetchSignInMethodsForEmail(firebaseAuth, email);
      if (methods.includes('google.com') || email.endsWith('@gmail.com')) {
        setShouldLoginWithGoogle(true);
        return false;
      }
      return true;
    } catch (e) {
      if (handleAuthCode(e)) {
        return false;
      } else {
        throw e;
      }
    }
  };

  const handleUserCredential = (userCredential: any) => {
    // @NOTE: we don't need to do anything here because
    // user-state -> FirebaseUserController listens for changes to the firebase user
    setLoading(true);
    sessionUserController.clearActionLinkToken();
    props.onAuth && props.onAuth();
  };

  const handleAuthCode = (e: unknown): boolean => {
    setLoading(false);
    if (!(e as any).code) {
      return false;
    }
    const { code } = e as any;
    // See: https://firebase.google.com/docs/auth/admin/errors
    switch (code) {
      case 'auth/wrong-password':
        setPasswordError(localePrimer.translate(locale, 'wrongPassword'));
        return true;
      case 'auth/user-not-found':
        setEmailError(localePrimer.translate(locale, 'emailNotFound'));
        return true;
      case 'auth/popup-closed-by-user':
        // @NOTE: this happens when the user closes the OAuth popup tab/window.
        return true;
      case 'auth/email-already-in-use':
        setEmailAlreadyInUse(true);
        return true;
      case 'auth/invalid-email':
        setEmailError(localePrimer.translate(locale, 'invalidEmail'));
        return true;
      default:
        setUnexpectedError(localePrimer.translate(locale, 'unexpectedError')(code));
        return true;
    }
  };

  const register = async () => {
    const validSignIn = await isValidEmailSignInMethod();
    if (!validSignIn) {
      return;
    }
    try {
      const userCredential = await createUserWithEmailAndPassword(firebaseAuth, email, password);
      handleUserCredential(userCredential);
    } catch (e) {
      if (handleAuthCode(e)) {
        return;
      } else {
        throw e;
      }
    }
  };

  const login = async () => {
    const validSignIn = await isValidEmailSignInMethod();
    if (!validSignIn) {
      return;
    }
    try {
      const userCredential = await signInWithEmailAndPassword(firebaseAuth, email, password);
      handleUserCredential(userCredential);
    } catch (e) {
      if (handleAuthCode(e)) {
        return;
      } else {
        throw e;
      }
    }
  };

  const submitEmailPasswordForm = () => {
    setUnexpectedError('');
    setConfirmationMessage('');
    const allValid = formFieldsAllValid();
    if (!allValid) {
      return;
    }
    setLoading(true);
    if (isRegistering && !emailAlreadyInUse) {
      register();
    } else {
      login();
    }
  };

  const changePassword = (v: string) => {
    setPassword(v);
    setPasswordError('');
  };

  const changeEmail = (v: string) => {
    setEmail(v);
    setEmailError('');
    setEmailAlreadyInUse(false);
  };

  const loginWithGoogle = () => {
    setLoading(true);
    _loginWithGoogle();
  };

  const _loginWithGoogle = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const userCredential = await signInWithPopup(firebaseAuth, provider);
      handleUserCredential(userCredential);
    } catch (e) {
      if (handleAuthCode(e)) {
        return;
      } else {
        throw e;
      }
    }
  };

  const handleForgotPassword = () => {
    _sendPasswordResetEmail();
  };

  const _sendPasswordResetEmail = async () => {
    const emailValidation = formValidator.validateEmail(email);
    if (emailValidation.error) {
      setEmailError(emailValidation.error);
      return;
    } else {
      setEmailError('');
    }
    setLoading(true);
    try {
      await sendPasswordResetEmail(firebaseAuth, email);
      setLoading(false);
      setConfirmationMessage(localePrimer.translate(locale, 'passwordResetEmailSent'));
    } catch (e) {
      if (handleAuthCode(e)) {
        return;
      } else {
        throw e;
      }
    }
  };

  if (shouldLoginWithGoogle) {
    return (
      <ButtonGroup noBottomPadding>
        <Paragraph
          align="center"
          text={localePrimer.translate(locale, 'loginWithGoogle')}
          color="error"
          noBottomPadding
        />
        <Button
          type="primary"
          testIdRoot="auth-element-google"
          onClick={loginWithGoogle}
          submitOnEnter
        >
          {localePrimer.translate(typeSpecificLocale, 'google')}
        </Button>
        <Button
          type="text"
          onClick={reset}
          testIdRoot="auth-element-cancel"
        >
          {localePrimer.translate('shared.cancel')}
        </Button>
      </ButtonGroup>
    );
  }

  if (selectedEmailAuth) {
    return (
      <Form background={props.background}>
        {!props.hideEmail &&
          <TextField
            label={localePrimer.translate(locale, 'email')}
            type="email"
            autoFocus
            error={emailAlreadyInUse ?
              localePrimer.translate(locale, 'emailAlreadyInUse') :
              emailError
            }
            onChange={changeEmail}
            value={email}
            testIdRoot="auth-element-email"
            loading={loading}
            autoComplete={AutoComplete.Email}
          />
        }
        <TextField
          label={localePrimer.translate(typeSpecificLocale, 'password')}
          type="password"
          error={passwordError}
          onChange={changePassword}
          value={password}
          testIdRoot="auth-element-password"
          loading={loading}
          autoComplete={isRegistering ? AutoComplete.NewPassword : AutoComplete.CurrentPassword}
        />
        <ButtonGroup noBottomPadding>
          {(unexpectedError || confirmationMessage) &&
            <Markdown
              align="center"
              markdown={unexpectedError || confirmationMessage}
              color={unexpectedError ? 'error' : undefined}
              noBottomPadding
            />
          }
          {emailAlreadyInUse &&
            <Button
              type="primary"
              onClick={submitEmailPasswordForm}
              loading={loading}
              testIdRoot="auth-element-submit-emailAlreadyInUse"
              submitOnEnter
            >
              {localePrimer.translate(locale, 'submitAsLogin')}
            </Button>
          }
          {!emailAlreadyInUse &&
            <Button
              type="primary"
              onClick={submitEmailPasswordForm}
              loading={loading}
              testIdRoot="auth-element-submit"
              submitOnEnter
            >
              {localePrimer.translate(typeSpecificLocale, 'submit')}
            </Button>
          }
          <Button
            type="secondary"
            onClick={reset}
            testIdRoot="auth-element-cancel"
          >
            {localePrimer.translate('shared.cancel')}
          </Button>
          {!isRegistering &&
            <Button
              type="text"
              small
              loading={loading}
              testIdRoot="auth-element-forgotPassword"
              onClick={handleForgotPassword}
            >
              {localePrimer.translate(locale, 'forgotPassword')}
            </Button>
          }
        </ButtonGroup>
      </Form>
    );
  }

  return (
    <ButtonGroup noBottomPadding direction="column">
      <Button
        type="secondary"
        testIdRoot="auth-element-google"
        onClick={loginWithGoogle}
      >
        {localePrimer.translate(typeSpecificLocale, 'google')}
      </Button>
      <Button
        type="secondary"
        onClick={() => setSelectedEmailAuth(true)}
        testIdRoot="auth-element-email"
      >
        {localePrimer.translate(typeSpecificLocale, 'email')}
      </Button>
      {unexpectedError &&
        <Markdown
          align="center"
          markdown={unexpectedError}
          color="error"
          noBottomPadding
        />
      }
    </ButtonGroup>
  );
};
