import React, { Fragment, FunctionComponent, ReactElement, useEffect, useState } from 'react';
import localePrimer from '../../../utils/localePrimer';
import {
  Button,
  ButtonGroup,
  H2,
  Markdown,
  Modal,
  ModalFooter,
  ModalHeader,
  Task,
} from '@pocketrn/rn-designsystem';
import { Acknowledgment, Acknowledgments, User } from '@pocketrn/entities/dist/core';
import { Person } from '@pocketrn/entities/dist/community';
import Signature from '../Signature';
import {
  AcknowledgmentType,
  NO_REQUIRE_SIGN_TERMS,
  REQUIRE_SIGN_TERMS,
  getTerms,
  useAcknowledgmentsState,
} from '../../../utils/useAcknowledgmentsState';
import { profileController, userController } from '../../../state/controllers';
import { useSelector } from 'react-redux';
import { selectSessionUser } from '../../../apps/user-state';
import { getManagedProperty } from '../../../utils/managedProperty';

const locale = 'core.acknowledgments';

export function addSignedAcknowledgmentToUser(
  user: User,
  acknowledgment: Acknowledgment,
): User {
  const _user = user.copy();
  if (!_user.acknowledgments) {
    _user.acknowledgments = new Acknowledgments({});
  }
  _user.acknowledgments = new Acknowledgments({
    ..._user.acknowledgments.acknowledgments,
    [acknowledgment.id]: acknowledgment,
  });
  return _user;
}

interface Props {
  user: User;
  person: Person;
  coSigner?: Person;
  onSigned?: (acknowledgment: Acknowledgment, newUser: User) => void;
  onNameChange?: (firstName: string, lastName: string, newPerson: Person) => void;
  onCoSignerNameChange?: (firstName: string, lastName: string, newCoSigner: Person) => void;
  onAllSigned?: () => void;
  saveType?: 'save' | 'next' | 'done';
  onBack?: () => void;
  backType?: 'back' | 'cancel';
  accountManagedByCaregiver?: boolean;
  skipIfAllSigned?: boolean;
}

const AcknowledgmentsForm:FunctionComponent<Props> = (props): ReactElement => {
  const sessionUser = useSelector(selectSessionUser);
  const { user, person, coSigner, accountManagedByCaregiver } = props;
  const [ showModal, setShowModal ] = useState(false);
  const [ modalKey, setModalKey ] = useState(undefined as AcknowledgmentType | undefined);
  const [ allSigned, setAllSigned ] = useState(false);
  const {
    allAcknowledgmentsSigned,
    getLegalName,
    isAcknowledgmentSigned,
  } = useAcknowledgmentsState(localePrimer);
  const [ loadingConsent, setLoadingConsent ] = useState<AcknowledgmentType | undefined>(undefined);
  const [ loadingNameChange, setLoadingNameChange ] = useState(false);

  useEffect(() => {
    const _allSigned = !!allAcknowledgmentsSigned(
      user, person, undefined, accountManagedByCaregiver ?? false,
    );
    if (props.onAllSigned && _allSigned) {
      props.onAllSigned();
    }
    setAllSigned(_allSigned);
  }, [user]);

  const toggleModal = (key: AcknowledgmentType) => {
    setModalKey(key);
    setShowModal(true);
  };

  const signAcknowledgment = (id: AcknowledgmentType | undefined, ignoreModalClose?: boolean) => {
    _signAcknowledgment(id, ignoreModalClose);
  };

  const _signAcknowledgment = async (
    id: AcknowledgmentType | undefined,
    ignoreModalClose?: boolean,
  ) => {
    if (!props.onSigned) {
      throw new Error('cannot sign because onAllSigned is not set');
    }
    if (!id) {
      throw new Error('id is undefined');
    }
    const legalName = getLegalName(person);
    if (!legalName) {
      throw new Error('person does not have fullLegalName');
    }
    const legalNameCoSigner = getLegalName(coSigner);
    const acknowledgment = new Acknowledgment(id, new Date(), legalName, legalNameCoSigner);
    const newUser = user.copy();
    if (!newUser.acknowledgments) {
      newUser.acknowledgments = new Acknowledgments({});
    }
    newUser.acknowledgments.acknowledgments[id] = acknowledgment;
    setLoadingConsent(id);
    await userController.acceptUserAcknowledgment(
      acknowledgment.id,
      acknowledgment.acceptedAt,
      acknowledgment.acceptedBy,
      acknowledgment.coAcceptedBy || undefined,
      sessionUser?.uid === user.uid ? undefined : getManagedProperty(user.uid),
    );
    setLoadingConsent(undefined);
    props.onSigned(acknowledgment, newUser);
    if (!ignoreModalClose) {
      setShowModal(false);
    }
  };

  const modalFooterContent = props.onSigned ? (
    <ModalFooter
      buttons={[
        <Button
          key={0}
          type="primary"
          onClick={() => signAcknowledgment(modalKey)}
          testIdRoot="acknowledgments-modalFooter-accept-btn"
        >
          {localePrimer.translate(locale, 'signature.acceptBtn')}
        </Button>,
        <Button
          key={1}
          type="text"
          onClick={() => setShowModal(false)}
          testIdRoot="acknowledgments-modalFooter-cancel-btn"
        >
          {localePrimer.translate('shared.cancel')}
        </Button>,
      ]}
    />
  ) : (
    <ModalFooter
      buttons={[
        <Button
          key={0}
          type="secondary"
          onClick={() => setShowModal(false)}
          testIdRoot="acknowledgments-modalFooter-cancel-btn"
        >
          {localePrimer.translate('shared.done')}
        </Button>,
      ]}
    />
  );

  const getTask = (id: AcknowledgmentType): ReactElement => {
    return (
      <Task
        key={id}
        active={false}
        complete={isAcknowledgmentSigned(user, person, id)}
        onToggle={REQUIRE_SIGN_TERMS.includes(id) || !props.onSigned ?
          () => toggleModal(id) :
          () => signAcknowledgment(id, true)
        }
        localePrimer={localePrimer}
        label={localePrimer.translate(locale, 'terms', id, 'title')}
        onViewTextClick={() => toggleModal(id)}
        testIdRoot={`acknowledgments-task-${id}`}
        loading={loadingConsent === id}
      />
    );
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleOnNameChange = (firstName: string, lastName: string, isCoSigner: boolean) => {
    _handleOnNameChange(firstName, lastName, isCoSigner);
  };

  const _handleOnNameChange = async (firstName: string, lastName: string, isCoSigner: boolean) => {
    if (!props.onNameChange) {
      throw new Error('cannot change name because onNameChange is not set');
    }
    if (isCoSigner) {
      if (!coSigner || !props.onCoSignerNameChange) {
        return;
      }
      const newCoSigner = coSigner.copy();
      newCoSigner.firstName = firstName;
      newCoSigner.lastName = lastName;
      props.onCoSignerNameChange(firstName, lastName, newCoSigner);
    } else {
      const newPerson = person.copy();
      newPerson.firstName = firstName;
      newPerson.lastName = lastName;
      setLoadingNameChange(true);
      await profileController.updateProfile(
        newPerson,
        sessionUser?.uid === user.uid ? undefined : getManagedProperty(user.uid),
      );
      setLoadingNameChange(false);
      props.onNameChange(firstName, lastName, newPerson);
    }
  };

  const getAcknowledgment = (id: AcknowledgmentType): Acknowledgment | undefined => {
    // @NOTE: if we are signing, we always get the actual signature of the current person
    // rather than the details of any previous acknowledgment signing.
    if (props.onSigned) {
      return undefined;
    }
    if (!user.acknowledgments?.acknowledgments) {
      return undefined;
    }
    return user.acknowledgments.acknowledgments[id];
  };

  return (
    <Fragment>
      {getTerms(user, accountManagedByCaregiver).map(term => getTask(term))}
      <ButtonGroup>
        {!!props.saveType &&
          <Button
            type="primary"
            onClick={props.onAllSigned}
            disabled={!allSigned}
            loading={!!loadingConsent}
            testIdRoot="acknowledgments-save-btn"
            submitOnEnter
          >
            {localePrimer.translate('shared', props.saveType)}
          </Button>
        }
        {!!props.backType &&
          <Button
            type="text"
            onClick={props.onBack}
            testIdRoot="acknowledgments-back-btn"
          >
            {localePrimer.translate('shared', props.backType)}
          </Button>
        }
      </ButtonGroup>
      {showModal && (
        <Modal
          onClose={closeModal}
          hide={!showModal}
          fullScreen
          header={
            <ModalHeader
              title={localePrimer.translate(locale, 'overline')}
              onClose={
                modalKey && NO_REQUIRE_SIGN_TERMS.includes(modalKey) ? closeModal : undefined
              }
            />
          }
          footer={
            modalKey && NO_REQUIRE_SIGN_TERMS.includes(modalKey) ? modalFooterContent : undefined
          }
          loading={!!(loadingConsent || loadingNameChange)}
        >
          <H2
            align="center"
            text={localePrimer.translate(locale, 'terms', `${modalKey}.title`)}
          />
          <Markdown
            size="sm"
            markdown={localePrimer.translate(locale, 'terms', `${modalKey}.content`)}
          />
          {(modalKey && REQUIRE_SIGN_TERMS.includes(modalKey)) &&
            <Signature
              loading={!!(loadingConsent || loadingNameChange)}
              user={user}
              person={person}
              onNameChange={props.onSigned ? handleOnNameChange : undefined}
              onAccept={props.onSigned ? () => signAcknowledgment(modalKey) : undefined}
              onCancel={() => setShowModal(false)}
              coSigner={coSigner}
              acknowledgment={getAcknowledgment(modalKey)}
            />
          }
        </Modal>
      )}
    </Fragment>
  );
};

export default AcknowledgmentsForm;
