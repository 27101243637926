import { ReduxAction, Action } from '@pocketrn/client/dist/entity-sdk';
import { Navigation } from '@pocketrn/rn-designsystem';

export const REDUCER_KEY = 'navbar';
const na = new ReduxAction(REDUCER_KEY);

export interface NavbarActiveNavAction extends Action {
  activeNavigation: Navigation;
}

export interface NavbarMeetingNotificationsAction extends Action {
  meetingNotifications: number;
}

export interface NavbarProfileImageAction extends Action {
  profileImage: string;
}

export interface NavbarOnlyLogoAction extends Action {
  onlyLogo: boolean;
}

export interface NavbarIsAccountSwitcherAction extends Action {
  isAccountSwitcher: boolean;
}

export interface NavbarShowLogoutAction extends Action {
  showLogout: boolean;
}

export const NavbarActionId = {
  SET_ACTIVE_NAVIGATION: na.actionType('SET_ACTIVE_NAVIGATION'),
  SET_MEETING_NOTIFICATIONS: na.actionType('SET_MEETING_NOTIFICATIONS'),
  SET_PROFILE_IMAGE: na.actionType('SET_PROFILE_IMAGE'),
  SET_ONLY_LOGO: na.actionType('SET_ONLY_LOGO'),
  SET_IS_ACCOUNT_SWITCHER: na.actionType('SET_IS_ACCOUNT_SWITCHER'),
  SET_SHOW_LOGOUT: na.actionType('SET_SHOW_LOGOUT'),
};

export class NavbarActions {
  public static setNavbarActiveNavAction(activeNavigation: Navigation): NavbarActiveNavAction {
    return {
      type: NavbarActionId.SET_ACTIVE_NAVIGATION,
      activeNavigation,
    };
  }

  public static setNavbarMeetingNotificationsAction(
    meetingNotifications: number,
  ): NavbarMeetingNotificationsAction {
    return {
      type: NavbarActionId.SET_MEETING_NOTIFICATIONS,
      meetingNotifications,
    };
  }

  public static setNavbarProfileImageAction(profileImage: string): NavbarProfileImageAction {
    return {
      type: NavbarActionId.SET_PROFILE_IMAGE,
      profileImage,
    };
  }

  public static setNavbarOnlyLogoAction(onlyLogo: boolean): NavbarOnlyLogoAction {
    return {
      type: NavbarActionId.SET_ONLY_LOGO,
      onlyLogo,
    };
  }

  public static setNavbarIsAccountSwitcherAction(isAccountSwitcher: boolean)
  : NavbarIsAccountSwitcherAction {
    return {
      type: NavbarActionId.SET_IS_ACCOUNT_SWITCHER,
      isAccountSwitcher,
    };
  }

  public static setNavbarShowLogoutAction(showLogout: boolean): NavbarShowLogoutAction {
    return {
      type: NavbarActionId.SET_SHOW_LOGOUT,
      showLogout,
    };
  };
}
